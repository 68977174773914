import axios from "axios";
import { PaginatedResult } from "../models/PaginatedResult";
import { app } from "..";
import { SearchOptions } from "../models/SearchOptions";
import { RadiusValues } from "../store/homeStore";


export const searchExchanges = async ({ page, queryText, categoryId, locationIds,
    radiusControlPosition, includeResultsOffgidExchanges,
    exchangeType, includeMyExchanges, orderBy }: Partial<SearchOptions> & { page: number }): Promise<PaginatedResult> => {
        
    try {
        const result = await axios.post<PaginatedResult>(`${app.apiBasePath}/search/exchanges`, {
            page: page,
            categoryId: categoryId,
            query: queryText,
            groups: locationIds,
            distanceMeters: RadiusValues[radiusControlPosition] * 1000,
            includeResultsOffgidExchanges: includeResultsOffgidExchanges,
            exchangeType: exchangeType,
            includeMyResults: includeMyExchanges,
            order: orderBy === "date" ? 0 : 1
        });

        return result.data;
    }
    catch (e) {
        console.warn(e);
    }
}
