import React, { ReactElement } from "react"; 
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import style from "../styles/anonym-master.module.scss";

import logo from "../images/jardinweb_logo_180x22.svg";

export interface AnonymousMasterProps {

}
export const AnonymousMaster = (props: AnonymousMasterProps): ReactElement => {


    return (

        <div className={style.container}>
            <header>
                <Link to={"/"}>
                    <img width={250} src={logo} alt={"JEU Jardin Web"} />
                </Link>
            </header>
            <Outlet />
        </div>

    );

}