import React, { ReactElement, useContext, useState } from 'react';
import { AppContext, LocalizationContext } from "../interfaces/AppContext";
import { ProfileDropdown } from './ProfileDropdown/ProfileDropdown';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../images/jardinweb_logo_180x22.svg';
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import style from '../styles/header.module.scss';
import eclipse from '../images/eclipse.png';

interface NotificationIndicatorProps {
    dummyCounter: number;
}

const NotificationIndicator = (props: NotificationIndicatorProps): ReactElement => {
    return (
        <div className={style["notice-view"]}>
            <span>{props.dummyCounter}</span>
            <div className="ico ico-bell-24"></div>
        </div>
    );
};

export interface HeaderProps {
    hidePostExchange?: boolean;
    hideNotifications?: boolean;
    showMobileMenu: () => void;
}

export const Header = (props: HeaderProps): ReactElement => {

    const val = useContext(AppContext);

    const { localization } = useContext(LocalizationContext);

    const [profileExtended, setProfileExtended] = useState(false);

    const toggleProfileMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        setProfileExtended(!profileExtended);
    };

    return (
        <React.Fragment>
            <header className={style.container}>
                <div>
                    <div className={style.leftside}>
                        <div onClick={props.showMobileMenu} className={style.mobilemenu}>
                            <FontAwesomeIcon icon="bars" />
                        </div>
                        <Link to="/">
                            <Logo className={style.logo} />
                        </Link>
                    </div>



                    <div className={style.rightside}>
                        {
                            props.hidePostExchange !== true &&
                            <Link to="/post/exchange" className={classNames("btn", style.publishbutton)} >
                                <span>{localization["PostOffer"]}</span>
                            </Link>
                        }
                        {
                            props.hideNotifications !== true &&
                            <NotificationIndicator dummyCounter={0} />
                        }
                        <div
                            onClick={toggleProfileMenu}
                            className={style.profile}>
                            <FontAwesomeIcon icon={faCircleUser} />
                        </div>
                    </div>
                </div>
            </header>
            <div style={{ position: "relative" }}>
                {
                    profileExtended &&
                    <ProfileDropdown closeDropdown={() => setProfileExtended(false)} person={val.person} />
                }

            </div>
        </React.Fragment>
    );

};