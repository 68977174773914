import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../misc/Utilities";
import { SearchOptions } from "../models/SearchOptions";
import { homeStore } from "../store/homeStore";

export type StorageItems = "search-options" | "__search_results" | "collapseSearch" | "admin-invites-lastgroup" | "results-scroll-pos";

export class LocalStorageService {

    personId: string;

    constructor(personId: string) {
        this.personId = personId;
    }

    getFromStorage<T>(key: StorageItems): T | null {
        const k = this.getPersonalizedKey(key);
        const data = localStorage.getItem(k);
        if (typeof data === "undefined" || data === null) {
            return null;
        }

        try {
            return JSON.parse(data);
        }
        catch {
            console.log("catch", k)
            return null;
        }
    }

    setToStorage(key: StorageItems, obj: any): void {
        const k = this.getPersonalizedKey(key);
        const _obj = JSON.stringify(obj);
        localStorage.setItem(k, _obj);
    }

    private getPersonalizedKey(key: string): string {
        return `${this.personId}.${key}`;
    };
}

export const loadSearchDateFromLocalStorage = (personId: string): void => {
    const storage = new LocalStorageService(personId);
    let searchOptions = storage.getFromStorage<SearchOptions>("search-options");
    if (!IsUndefinedOrNull(searchOptions)) {
        const store = homeStore.getState();
        Object.keys(searchOptions).forEach(key => {
            if (searchOptions[key]) {
                store.update(key as keyof SearchOptions, searchOptions[key]);
            }
        });
    }
}   