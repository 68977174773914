import { ReactElement, useContext } from "react";
import { useParams } from "react-router-dom";
import { Invitations } from "../../Facilitators/Invitations";
import { StringIsNullOrEmpty } from "../../../misc/Utilities"; 
import { AppContext } from "../../../interfaces/AppContext";
import { InvitationFormAdmin } from "./InvitationFormAdmin";

export const AdminInviteControl = (): ReactElement => {

    const params = useParams();
    const suboption = params["suboption"];
    const groupid = params["groupid"];


    const { localGroups } = useContext(AppContext);

    const groupName  =localGroups.find(g=>g.id === groupid);

    return <div style={{ padding: "10px" }}>
        {
            StringIsNullOrEmpty(groupid) &&
            <Invitations adminMode={true} groupId={""} view={suboption} />
        }
        {
            !StringIsNullOrEmpty(groupid) &&
            <InvitationFormAdmin groupId={groupid} groupName={groupName?.name ?? "n/a"} />
        }
    </div>
}