import axios from "axios";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { app } from "../..";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { HasPrivilegeOrDev, IsNullOrWhiteSpace, IsUndefinedOrNull, StringIsNullOrEmpty } from "../../misc/Utilities";
import { InvitationState, Role } from "../../models/Enums";
import { Invitation } from "../../models/Invitation";
import style from "./invitation-form.module.scss";
import moment from "moment";
import { StatusCodes } from "http-status-codes";
import { NotificationType, NotificationView } from "../../components/NotificationView";

export const InvitationEditForm = ({ invitation, adminMode }: { invitation: Invitation, adminMode: boolean }): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const { person } = useContext(AppContext);
    const [cancel, setCancel] = useState(false);
    const [note, setNote] = useState("");
    const [busy, setBusy] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const { groupid } = useParams();

    const nav = useNavigate();

    const voidInvitation = (): void => {
        setBusy(true);

        const path = adminMode ?
            `${app.apiBasePath}/admin/users/invitations/${invitation.groupId}/cancelinvitation` :
            `${app.apiBasePath}/groupadmin/${invitation.groupId}/cancelinvitation`;

        const request = axios.post(path, {
            invitationId: invitation.id,
            groupId: invitation.groupId,
            justification: note.trim()
        });

        request.then((result) => {
            setCancel(false);
            nav(-1 as any);
        })

    };

    const inviteReadyToBeResent = (inv: Invitation): boolean => {
        if (IsNullOrWhiteSpace(inv?.lastEmailResend)) {
            return true;
        }
        else {
            const min = getMinutesDiffFromDate(inv?.lastEmailResend)
            return min > 5 || min === null;
        }
    }

    const getMinutesDiffFromDate = (date: string): number | null => {
        try {
            let d = moment(date);
            let duration = moment.duration(moment().diff(d));
            return duration.asMinutes();
        }
        catch {
            return null;
        }
    }

    const minDelta = getMinutesDiffFromDate(invitation?.lastEmailResend);

    const resendInvite = (): void => {

        const path = adminMode ?
            `${app.apiBasePath}/admin/users/invitations/${invitation.groupId}/reinvite` :
            `${app.apiBasePath}/groupadmin/${invitation.groupId}/reinvite`;

        axios.post(path, {
            email: invitation.emailAddress,
            groupId: invitation.groupId
        }).then((result) => {

            if (result.status === StatusCodes.OK) {
                setShowConfirmation(true);
                window.setTimeout(() => {
                    setShowConfirmation(false);
                }, 10000);
            }
            else {
                console.error("error resending invite");
            }
        });
    }


    useEffect(() => {
        if (IsUndefinedOrNull(invitation) || invitation.state !== InvitationState.Opened && invitation.state !== InvitationState.New) {

            if (adminMode) {
                nav(`/admin/invitations`);
            }
            else {
                nav(`/group/manage/${groupid}/invitations`);
            }
        }

    }, []);

    if (IsUndefinedOrNull(invitation)) {
        return null;
    }

    return (
        <div className={style.container}>
            <div className="form">
                <div className={style.cancellation}>
                    {
                        cancel &&
                        <span className={style.c}>{localization["cancel"]}</span>
                    }
                    <h3>Invitation pour <span>{invitation.lastName} {invitation.firstName}</span></h3>
                    <div className="row">
                        <label>envoyé à</label>
                        <span>{invitation.emailAddress}</span>
                    </div>
                    <div className="row">
                        <code>{invitation.uniqueShortTokenId}</code>
                    </div>
                </div>
                <div className="row">
                    {
                        !cancel &&

                        <div className={style.buttons}>
                            {
                                (invitation.state === InvitationState.New ||
                                    invitation.state === InvitationState.Opened) &&
                                <>
                                    {
                                        <div>
                                            <button disabled={showConfirmation} onClick={() => resendInvite()} className="btn action">
                                                {localization["resend"]}
                                                {localization["email"]}
                                            </button>
                                        </div>
                                    }       

                                </>
                            }
                            {
                                (invitation.state === InvitationState.New || invitation.state === InvitationState.Opened) &&

                                <div>
                                    <button disabled={busy} className="btn action" onClick={() => nav(-1)}>{localization["return"]}</button>
                                    <button className="btn cancel" onClick={() => setCancel(true)}>
                                        {localization["revoke"]}
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    {
                        showConfirmation &&
                        <div style={{
                            margin:"20px 0 0 0",
                            maxWidth:"300px"
                        }}>
                            <NotificationView type={NotificationType.Info}>
                                <div>
                                    <p>Success!</p>
                                </div>
                            </NotificationView>
                        </div>

                    }
                    {
                        cancel &&
                        <div>
                            <label>{localization["justifyRequest"]}</label>

                            <div className={style.comment}>
                                <textarea disabled={busy} value={note} onChange={(e) => setNote(e.target.value)} className="form-control"></textarea>
                            </div>
                            <div className={style.buttons}>
                                <button disabled={busy} className="btn cancel" onClick={() => setCancel(false)}>{localization["cancel"]}</button>
                                <button disabled={StringIsNullOrEmpty(note?.trim()) || busy} className="btn action" onClick={voidInvitation}>{localization["submit"]}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
