import { ReactElement } from "react";
import style from "./manage.module.scss";
import { Link } from "react-router-dom";


export const GroupsManagement = (): ReactElement => {


    return (
        <div className={style.container}>
            <section className={style.heading}>
                <Link to={"add"}>Create group</Link>
            </section>

            <div className={style.content}>
            </div>

        </div>
    );
}