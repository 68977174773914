import React, { useContext, useState } from 'react';
import axios from 'axios';
import { NotificationView, NotificationType } from '../../components/NotificationView';
import { useNavigate } from 'react-router';
import { IsNullOrWhiteSpace, TestPasswordComplexity } from '../../misc/Utilities';
import { LocalizationContext } from '../../interfaces/AppContext';
import { InputWarning } from '../../components/InputWarning';
import { app } from '../..';
import { PwdValidState } from '../../models/Enums';


const ChangePassword = (props): JSX.Element => {
    const nav = useNavigate();

    const vContext = useContext(LocalizationContext);
    const v = vContext.localization;

    const [currentPwd, setCurrentPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [newPwd2, setNewPwd2] = useState('');
    const [isChanging, setIsChanging] = useState(false);
    const [currentPwdValState, setCurrentPwdValState] = useState(PwdValidState.InitialState);
    const [newPwdValState, setNewPwdValState] = useState(PwdValidState.InitialState);
    const [newPwd2ValState, setNewPwd2ValState] = useState(PwdValidState.InitialState);
    const [updateDone, setUpdateDone] = useState(false);

    const OnCurrentPwdChange = (e): void => {
        const pwd = e.target.value;
        setCurrentPwd(pwd);
        setCurrentPwdValState(IsNullOrWhiteSpace(pwd) ? PwdValidState.InitialState : PwdValidState.ValidationPending);
    };

    const ChangePassword = (): void => {
        if (currentPwdValState === PwdValidState.Ok &&
            newPwdValState === PwdValidState.Ok && newPwd2ValState === PwdValidState.Ok) {

            setIsChanging(true);

            let request = axios.post(`${app.apiBasePath}/auth/changepwd`, {
                currentPassword: currentPwd,
                newPassword: newPwd
            });

            request.then(result => {

                if (result.data.accepted) {
                    setUpdateDone(true);
                    setCurrentPwd('');
                    setNewPwd('');
                    setNewPwd2('');
                    setCurrentPwdValState(PwdValidState.InitialState);
                    setNewPwd2ValState(PwdValidState.InitialState);
                    setNewPwdValState(PwdValidState.InitialState);
                    setUpdateDone(true);

                    window.setTimeout(() => {
                        setUpdateDone(false);
                        setIsChanging(false);
                    }, 3000);
                }
            });
        }
    }

    const CancelChange = (): void => {

        nav(-1);
    };

    const ValidateCurrentPassword = (): void => {
        if (IsNullOrWhiteSpace(currentPwd))
            return;

        const request = axios.post(`${app.apiBasePath}/auth/validatepwd`, {
            currentPassword: currentPwd
        });

        request.then(result => {
            if (result.data.accepted) {
                setCurrentPwdValState(PwdValidState.Ok);
            }
            else {
                setCurrentPwdValState(PwdValidState.Invalid);
            }
        })
    }

    const OnNewPwdChange = (e): void => {

        const pwd = e.target.value;

        let s = PwdValidState.InitialState;
        let s2 = PwdValidState.InitialState;

        if (!IsNullOrWhiteSpace(pwd)) {
            s2 = PwdValidState.DoesNotMatch;

            if (pwd === currentPwd) {
                s = PwdValidState.MustNotEqualOld;
            }
            else {
                if (TestPasswordComplexity(pwd)) {
                    s = PwdValidState.Ok;
                }
                else {
                    s = PwdValidState.LowComplexity;
                }
            }
        }

        setNewPwd(pwd);
        setNewPwdValState(s);
        setNewPwd2ValState(s2);
    }

    const OnNew2PwdChange = (e): void => {
        const pwd = e.target.value;

        let s = PwdValidState.InitialState;

        if (!IsNullOrWhiteSpace(pwd)) {
            s = pwd === newPwd ? PwdValidState.Ok : PwdValidState.DoesNotMatch;
        }

        setNewPwd2(pwd);
        setNewPwd2ValState(s);
    };

    return (
        <div className="page-container">
            <div className="form pwd">
                <div className="row">
                    <h1>{v["ChangePassword"]}</h1>
                </div>

                <div className="row">
                    <label>{v["CurrentPassword"]}</label>
                    <input
                        type="password"
                        disabled={isChanging}
                        autoComplete="chrome-off"
                        onChange={OnCurrentPwdChange.bind(this)}
                        onBlur={ValidateCurrentPassword.bind(this)}
                        value={currentPwd}
                        className={currentPwdValState === PwdValidState.Ok ||
                            currentPwdValState === PwdValidState.InitialState ||
                            currentPwdValState === PwdValidState.ValidationPending
                            ? "form-control" : "form-control validation-warning"} />
                    {
                        currentPwdValState === PwdValidState.Invalid &&
                        <InputWarning messageKey="CurrentPasswordNotValid" />
                    }
                    {
                        currentPwdValState === PwdValidState.InitialState &&
                        <InputWarning messageKey="TypePasswordPlaceholder" />
                    }

                </div>
                <div className="row">
                    <label>{v["TypeNewPassword"]}</label>
                    <input
                        disabled={isChanging}
                        onChange={OnNewPwdChange.bind(this)}
                        type="password"
                        autoComplete="chrome-off"
                        className={newPwdValState === PwdValidState.Ok || newPwdValState === PwdValidState.InitialState
                            ? "form-control" : "form-control validation-warning"}
                        value={newPwd}
                    />
                    {
                        newPwdValState === PwdValidState.MustNotEqualOld &&
                        <InputWarning messageKey="NewPasswordMustBeDifferent" />
                    }
                    {
                        newPwdValState === PwdValidState.LowComplexity &&
                        <InputWarning messageKey="PasswordNotStrong" />
                    }

                </div>
                <div className="row">
                <label>{v["RetypeNewPassword"]}</label>
                    <input
                        disabled={isChanging}
                        onChange={OnNew2PwdChange.bind(this)}
                        type="password"
                        autoComplete="chrome-off"
                        className={newPwd2ValState === PwdValidState.Ok || newPwd2ValState === PwdValidState.InitialState
                            ? "form-control" : "form-control validation-warning"}
                        value={newPwd2}
                    />

                    {
                        newPwd2ValState === PwdValidState.DoesNotMatch &&
                        <InputWarning messageKey="PasswordsMustMatch" />
                    }
                </div>


                <div className="row buttons">
                    <button className="btn cancel" onClick={CancelChange.bind(this)}>
                        {v["cancel"]}
                    </button>
                    {
                        currentPwdValState === PwdValidState.Ok &&
                        newPwdValState === PwdValidState.Ok &&
                        newPwd2ValState === PwdValidState.Ok &&
                        <button disabled={isChanging} onClick={ChangePassword.bind(this)} className="btn action">{v["Update"]}</button>
                    }
                </div>

                {
                    updateDone &&
                    <NotificationView type={NotificationType.Info}>
                        <p>{v["PasswordChangeSuccess"]}</p>
                    </NotificationView>
                }

            </div>
        </div>
    );
}

export { ChangePassword }