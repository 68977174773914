import { useState } from "react";
import { IsUndefinedOrNull } from "../misc/Utilities";

export interface ErrorArg {
    isValid: boolean;
    message: string;
}


export interface ValidationMethods<TModel> {
    validator: any;
    use: (model: TModel) => ErrorArg;
}

export interface ValidationResult<TErrorValidators> {
    isValid: boolean; // this is global for all errors
    is: (validator: TErrorValidators) => ErrorArg;
}

const validate = <TErrorValidators>(validations: ValidationMethods<TErrorValidators>[], values): ValidationResult<TErrorValidators> => {

    const errors = validations.map((validation, idx: number) => {
        return {
            validator: validation.validator,
            result: validation.use(values)
        };
    });

    const valid = errors.every(r => r.result.isValid);

    const errorChecker = (validator: TErrorValidators): ErrorArg => {

        const obj = errors.find(p => p.validator === validator);
        if (IsUndefinedOrNull(obj)) {
            return { isValid: false, message: `No validation handler found for ${validator}` }
        }
        else return obj.result;
    };

    return { isValid: valid, is: errorChecker };
};

export type Setter = { name: string; value: any; };

export interface FormOptions<TModel, TErrorValidators> {
    values: TModel;
    changeHandler: (event: any) => void;
    valueSetter: (setter: Setter | Setter[]) => void;
    isValid: boolean;
    error: (validator: TErrorValidators) => ErrorArg;
    touched: any;
    touchReset: () => void;
}

export const useForm = <TModel, TErrorValidators>(initialState: TModel, validations = [], onSubmit = () => { }): FormOptions<TModel, TErrorValidators> => {
    
    const { isValid: initialIsValid, is: errorChecker } = validate<TErrorValidators>(validations, initialState);

    const [values, setValues] = useState(initialState as TModel);

    const [errors, setError] = useState(() => {
        return () => errorChecker;
    });

    const [isValid, setValid] = useState(initialIsValid);

    const initialTouch = {};

    Object.getOwnPropertyNames(initialState).forEach(name => {
        initialTouch[name] = false;
    });

    const [touched, setTouched] = useState(initialTouch as TModel);

    const changeHandler = event => {
        const newValues = { ...values, [event.target.name]: event.target.value } as TModel;
        const { isValid, is } = validate<TErrorValidators>(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setError(() => () => is);
        setTouched({ ...touched, [event.target.name]: true });
    };

    const valueSetter = (setter: Setter | Setter[]): void => {
        if (Array.isArray(setter)) {
            setMultiValue(setter as Setter[]);
        }
        else {
            setSingleValue(setter);
        }
    };

    const setSingleValue = (setter: Setter): void => {
        const { name } = setter;
        const { value } = setter;
        const newValues = { ...values, [name]: value } as TModel;
        const { isValid, is } = validate<TErrorValidators>(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setError(() => () => is);
        setTouched({ ...touched, [name]: true });
    }

    const setMultiValue = (setter: Setter[]): void => {

        let mod = {};
        let touch = {};
        setter.forEach(s => {
            mod[s.name] = s.value;
            touch[s.name] = true;
        });

        const newValues = { ...values, ...mod } as TModel;
        const { isValid, is } = validate<TErrorValidators>(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setError(() => () => is);
        setTouched({ ...touched, ...touch });
    }

    let error = errors();

    const touchReset = (): void => {
        setTouched(old => {
            Object.getOwnPropertyNames(old).forEach(n => {
                old[n] = false;
            });
            return { ...old }
        });
    }

    return { values, changeHandler, valueSetter, isValid, error, touched, touchReset }
};