export enum PersonStatus {
    New = 0,
    Active,
    Suspended,
    Deleted
}

export enum Role {
    None = 0,
    User = 1 << 0,
    UserManagement = 1 << 1,
    GroupManagement = 1 << 2,
    CategoryManagement = 1 << 3,
    SystemTester = 1 << 4,
    VocabularyManagement = 1 << 5,
    Developper = 1 << 6
}

export enum StorageType {
    Avatar = 0,
    PersonalFile,
    OfferDemand,
    Other
}

export enum GroupPrivileges {
    None = 0,
    Observer = 1 << 0,
    CanSearch = 1 << 1,
    CanPost = 1 << 2,
    Facilitator = 1 << 3,
    Admin = 1 << 4
}

export enum AttachmentType {
    Image = 0,
    Document = 1,
    Other = 2
}

export enum ExchangeState {
    Draft = 0,
    Published = 1
}

export enum InvitationState {
    New = 0,
    Opened,
    Redeemed,
    Cancelled,
    DeletedAccount,
}
export enum PwdValidState {
    InitialState = 0,
    Ok,
    DoesNotMatch,
    Invalid,
    ValidationPending,
    MustNotEqualOld,
    LowComplexity
}