import { ReactElement, useContext, useState } from "react";
import style from "./vocabulary.module.scss";
import axios from "axios";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { OptionType } from "../../../interfaces/OptionType";
import Select from "react-select";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router";
import { IsUndefinedOrNull } from "../../../misc/Utilities";
import { Link } from "react-router-dom";
import { Vocabulary } from "../../../models/Vocabulary";
import { app } from "../../..";

export const VocabularyForm = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const param = useParams();
    const nav = useNavigate();


    const options: OptionType[] = [{
        label: "Français",
        value: "fr"
    }, {
        label: "English",
        value: "en"
    }];

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    let initial = options[0];
    if (!IsUndefinedOrNull(param.locale)) {
        const p = options.find(x => x.value === param.locale);
        if (p) {
            initial = p;
        }
    }

    const [working, setWorking] = useState(false);
    const [currentLang, setCurrentLang] = useState<OptionType>(initial);

    const [msg, setMsg] = useState("");


    let returnPath = "/admin/vocabulary"
    if (!IsUndefinedOrNull(param.locale)) {
        returnPath += "/" + param.locale;
    }

    const addNewEntry = (): void => {

        const model: Vocabulary = {
            key: key,
            value: value,
            culture: currentLang.value as any,
            id: ""
        };

        setWorking(true);
        axios.post(`${app.apiBasePath}/admin/vocabulary/upsert`, model).then(r => {
            setWorking(false);
            setMsg("done");
            setTimeout(() => setMsg(""), 2000);
        });
    };

    const keyValid = (): boolean => {
        return /^[a-zA-Z0-9-_]{4,50}$/.test(key);
    }


    return (
        <div className={style.container}>
            <div className={style.heading}>
                <h1>Add new entry</h1>
            </div>

            <div className={classNames("form", style.form)}>
                <div className="row">
                    <Select onChange={v => setCurrentLang(v)} value={currentLang} options={options} />
                </div>
                <div className="row">
                    <label>key</label>
                    <input type="text" value={key} onChange={e => setKey(e.target.value)} className={classNames("form-control", !keyValid() ? style.warning : null)} />
                    {
                        !keyValid() &&
                        <pre>a-zA-Z-_</pre>
                    }
                </div>
                <div className="row">
                    <label>value</label>
                    <input type="text" value={value} onChange={e => setValue(e.target.value)} className="form-control" />
                </div>
                <div className="row">
                    {

                        !working &&
                        <Link style={{ marginRight: "10px" }} className="btn cancel" to={returnPath}>{localization["return"]}</Link>
                    }
                    <button disabled={!keyValid() || working} onClick={addNewEntry} className="btn save">{localization["submit"]}</button>
                </div>

                {
                    msg !== "" &&
                    <div className="row">
                        <span className={style.alert}>{msg}</span>
                    </div>
                }


            </div>
        </div>
    )
};