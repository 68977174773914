import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { app } from '..';
import { Category } from '../models/Category';
import { Configuration } from '../interfaces/Configuration';
import { Dictionary } from '../interfaces/Dictionary';
import { IJeuContext } from "../interfaces/IJeuContext";
import { FavoriteExchange } from '../models/FavoriteExchange';
import { Exchange } from '../models/Exchange';
import { PostalCodeData } from '../models/PostalCodeData';
import { IsNullOrWhiteSpace } from './Utilities';
import { Location } from '../models/Location';
import { Locale } from '../models/Locale';

export type ContextResult = {
    success: boolean;
    context: IJeuContext | null;
    error: string;
};

export type ContextRequest2 = {
    personInfo: boolean;
    groupInfo: boolean;
    token: boolean;
    terms: boolean;
}

export type ContextRequest = "personInfo" | "groupInfo" | "token" | "terms";

export const GetContext = (...args: ContextRequest[]): Promise<ContextResult> => {

    if (args.length === 0) {
        return Promise.reject("no arguments provided");
    }

    const distinctArgs = args.filter((value, index, self) => self.indexOf(value) === index);

    return new Promise((resolve, reject) => {

        let requestPath = `${app.apiBasePath}/auth/context?`;

        distinctArgs.forEach((arg, idx) => {
            if (idx > 0) requestPath += "&";
            requestPath += `${arg}=true`;
        });

        const request = axios.get(requestPath, {
            validateStatus: function (status) {
                return status < 500;
            }
        });

        request.then(result => {

            if (result.status === StatusCodes.OK) {
                const r: ContextResult = {
                    success: true,
                    context: result.data,
                    error: ""
                };

                resolve(r);
            }
            else if (result.status === StatusCodes.UNAUTHORIZED) {
                resolve({
                    success: false,
                    context: null,
                    error: "login"
                });
            }
            else reject("failure");
        });

        request.catch((error: AxiosError<{ additionalInfo: string }>) => {
            resolve({
                success: false,
                context: null,
                error: error.message
            });
        });

    });
};


export const GetLocalization = (locale: Locale): Promise<Dictionary<string>> => {

    return new Promise((resolve, reject) => {

        let localeResult = axios.get(`${app.apiBasePath}/public/localization/${locale}`);

        localeResult.catch(e => {
            reject(e);
        });

        localeResult.then(result => {
            if (result.status === StatusCodes.OK) {
                resolve(result.data);
            }
        });
    });
};

export const GetConfiguration = async (): Promise<Configuration> => {

    const isDev = window.location.hostname === "localhost";
    let config = await axios.get(isDev ? "/config.dev.json" : "/config.json");
    return config.data as Configuration;
};

export const GetCategories = (): Promise<Category[]> => {

    return new Promise((resolve, reject) => {
        const request = axios.get(app.apiBasePath + "/assets/categories");

        request.then(result => {
            resolve(result.data);
        });

        request.catch((error: AxiosError<{ additionalInfo: string }>) => {
            if (error.response.status === 401)
                reject("login");
            else
                reject(error);
        });
    });
};



export const GetVocabularyAdmin = (locale: Locale): Promise<Dictionary<string>> => {

    return new Promise((resolve, reject) => {

        const request = axios.get(`${app.apiBasePath}/admin/vocabulary?locale=${locale}`);

        request.then(result => {
            resolve(result.data);
        });

        request.catch((error: AxiosError<{ additionalInfo: string }>) => {
            if (error.response.status === 401)
                reject("login");
            else
                reject(error);
        });
    });
};

// ADDED SELECT EXCHANGES
// SET THEM INTO UI MENUS
export const GetMyExchanges = (): Promise<Exchange[]> => {

    return new Promise((resolve, reject) => {
        const request = axios.get(app.apiBasePath + "/exchange/all");

        request.then(result => {

            const data = result.data as Exchange[];
            resolve(data);
        });

        request.catch((error: AxiosError<{ additionalInfo: string }>) => {
            if (error.response.status === 401)
                reject("login");
            else
                reject(error);
        });
    });
};

export const GetMyFavoriteExchanges = async (): Promise<FavoriteExchange[]> => {
    try {
        const result = await axios.get(`${app.apiBasePath}/profile/favorites/exchanges`);
        return result.data as FavoriteExchange[];
    }
    catch {
        return Promise.reject("failed to fetch favorites");
    }
};

export const FindPostalCode = async (input: string): Promise<PostalCodeData[]> => {
    if (IsNullOrWhiteSpace(input))
        return Promise.resolve(null);

    const result = await axios.get(app.apiBasePath + "/search/postalcode/" + input);

    if (result.status === StatusCodes.NO_CONTENT) {
        return Promise.resolve(null);
    }

    const code = result.data as PostalCodeData;
    return Promise.resolve([code]);
};

export const FindPostalCodes = async (input: string): Promise<PostalCodeData[]> => {
    if (IsNullOrWhiteSpace(input))
        return Promise.resolve(null);

    const result = await axios.get(app.apiBasePath + "/search/postalcode/find/" + input);

    if (result.status === StatusCodes.NO_CONTENT) {
        return Promise.resolve(null);
    }

    const codes = result.data as PostalCodeData[];
    return Promise.resolve(codes);
};

export const GetCountries = async (): Promise<Location[] | null> => {
    const result = await axios.get(`${app.apiBasePath}/admin/countries`);
    return result.status === StatusCodes.OK ? result.data : null;
}

export const GetRegions = async (id?: string): Promise<Location[] | null> => {
    const result = await axios.get(`${app.apiBasePath}/admin/regions?id=${id}`);
    return result.status === StatusCodes.OK ? result.data : null;
}

export const GetGroups = async (locationId: string): Promise<Location[] | null> => {
    const result = await axios.get(`${app.apiBasePath}/admin/groups?locationId=${locationId}`);
    return result.status === StatusCodes.OK ? result.data : null;
}