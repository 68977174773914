import { OptionTypeDescription } from '../components/SelectExtension/SelectExtension';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { IsUndefinedOrNull } from './Utilities';


export const OptionTypeDescriptionFilter = (candidate: FilterOptionOption<OptionTypeDescription>, input: string): boolean => {

    var s = new RegExp(input, 'i');

    const label = candidate.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const testDescription = !IsUndefinedOrNull(candidate?.data?.description);
    const testExample = !IsUndefinedOrNull(candidate?.data?.example);

    let description = "";
    let example = "";

    if (testDescription) {
        description = candidate.data.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    if (testExample) {
        example = candidate.data.example.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    return s.test(label) || (testDescription && s.test(description)) || (testExample && s.test(example));   
};