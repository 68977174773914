import * as React from 'react';
import { NavCheckItem, NavItem } from '../interfaces/NavItem';

import { IsNullOrWhiteSpace, IsUndefinedOrNull, StringIsNullOrEmpty } from '../misc/Utilities';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/menu.scss';
import { NavLink } from 'react-router-dom';
import { Checkbox } from './Checkbox';


interface ILinkView {
    item: NavItem | NavCheckItem;
}

class LinkViewState {
    collapsed: boolean;
}


const menuHasSubItems = (items: (NavItem | NavCheckItem)[]): boolean => {
    return !IsUndefinedOrNull(items) && Array.isArray(items) && items.length > 0;
};


class LinkListView extends React.Component<ILinkView, LinkViewState>{

    state = {
        collapsed: false
    };

    render(): JSX.Element {
        const item = this.props.item as NavItem;
        return (

            <div className="link-item-container">
                <div className="link-items root">

                    <div className="link-item">
                        <div className="f00" onClick={this.onRootLinkClick.bind(this)}>
                            {
                                !IsNullOrWhiteSpace(item.iconClass) &&
                                <div className={classNames("icon", item.iconClass)}></div>
                            }
                            {
                                !IsUndefinedOrNull(item.fontAwesomeName) &&
                                <FontAwesomeIcon style={{ color: item.color }} className="icon" icon={item.fontAwesomeName} />
                            }
                            <span>{item.label}</span>
                            <div className={this.state.collapsed ? "icon alight-right ico-triangle-24 c" : "icon alight-right ico-triangle-24 e"}></div>
                        </div>
                    </div>

                    <div className={this.state.collapsed ? "link-collection collapsed" : "link-collection epxanded"}>
                        <ul>
                            {
                                item.items.map((m, i) => {
                                    return <li key={"dssd221" + i}><LinkViewChild item={m} /></li>;
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

        );
    }


    componentDidMount(): void {

    }

    onRootLinkClick(): void {
        let s = new LinkViewState();
        s.collapsed = !this.state.collapsed;
        this.setState(s);
    }

}

const CheckViewChild = (prop: ILinkView): React.ReactElement => {
    /*
    checked: boolean;
    onCheckChange: Function;
    disabled?: boolean;
    label: string;
    cssClass?: string;
    */

    const item = prop.item as NavCheckItem;

    return (
        <div className="link-item hover-off">
            <Checkbox
                label={item.label}
                onCheckChange={() => item.onCheck(item.id)}
                checked={false}
                disabled={item.disabled}
            />
        </div>
    );
}

const LinkViewChild = (prop: ILinkView): React.ReactElement => {


    const isCheckItem = Object.getOwnPropertyNames(prop.item).some(p => p === "onCheck");

    if (isCheckItem) {
        return CheckViewChild(prop);
    }

    const navItem = prop.item as NavItem;

    const onLinkClick = (): void => {
        if (prop.item.disabled) {
            return;
        }

        if (typeof navItem.onAction === "function") {
            navItem.onAction.apply(null, [navItem.key]);
        }
    }

    const renderWithIcon = (item: NavItem): JSX.Element => {


        if (typeof item.component !== "undefined" && item.component !== null) {
            return <div className="link-item">{item.component}</div>
        }

        return (
            <div className="link-item">
                <div className="f00" onClick={onLinkClick}>
                    {
                        !IsNullOrWhiteSpace(item.iconClass) &&
                        <div className={classNames("icon", item.iconClass)}></div>
                    }
                    {
                        !IsUndefinedOrNull(item.fontAwesomeName) &&
                        <FontAwesomeIcon className="icon" style={{ color: item.color }} icon={item.fontAwesomeName} />
                    }

                    {
                        !IsNullOrWhiteSpace(item.path) &&
                        !item.disabled &&
                        item.hardLink !== true &&
                        <NavLink className={(navData) => navData.isActive ? "active" : ""} to={item.path}>{item.label}</NavLink >
                    }
                    {
                        !IsNullOrWhiteSpace(item.path) &&
                        !item.disabled &&
                        item.hardLink === true &&
                        <a href={item.path}>{item.label}</a>
                    }
                    {
                        !IsNullOrWhiteSpace(item.path) &&
                        item.disabled &&
                        <span>{item.label}</span>
                    }
                    {
                        IsNullOrWhiteSpace(navItem.path) &&
                        <span>{item.label}</span>
                    }
                </div>
            </div>
        );
    }

    const renderBasic = (item: NavItem): JSX.Element => {


        if (typeof item.component !== "undefined" && item.component !== null) {
            return <div className="link-item">{item.component}</div>
        }

        return (
            <div className="link-item">
                {
                    !IsNullOrWhiteSpace(item.path) &&
                    !item.disabled &&
                    <NavLink className={(navData) => navData.isActive ? "active" : ""} to={item.path}>{item.label}</NavLink >
                }
                {
                    !IsNullOrWhiteSpace(item.path) &&
                    item.disabled &&
                    <span onClick={() => { }}>{item.label}</span>
                }
                {
                    IsNullOrWhiteSpace(navItem.path) &&
                    <span onClick={onLinkClick.bind(this)}>{item.label}</span>
                }

            </div>
        );
    }

    const hasIcon = !IsNullOrWhiteSpace(navItem.iconClass) || !IsUndefinedOrNull(navItem.fontAwesomeName);


    if (hasIcon) {
        return renderWithIcon(navItem);
    }
    else {
        return renderBasic(navItem);
    }
}

const LinkView = (prop: ILinkView) => {

    const hasSubItems = menuHasSubItems(prop.item.items);

    if (hasSubItems) {
        return <LinkListView item={prop.item} />;
    }
    else {
        return (
            <div className="link-item-container">
                <LinkViewChild item={prop.item} />
            </div>
        );
    }
}

export interface MenuProps {
    links: NavItem[];
}

const Menu = (props: MenuProps): JSX.Element => {

    if (IsUndefinedOrNull(props.links)) {
        return <div className="menu-container"></div>;
    }

    return (
        <div className="menu-container">
            {
                props.links.map((m, i) => {
                    return <LinkView key={"dsads__sd3d" + i} item={m} />;
                })
            }
        </div>
    );
}

export { Menu };