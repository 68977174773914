import { ReactElement } from "react"

import style from "./filterview.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export interface FilterViewProps {
    label: string;
    onClick?: () => void;
};

export const FilterView = (props: FilterViewProps): ReactElement => {

    const { label, onClick } = props;
    return (
        <div onClick={() => typeof onClick === "function" && onClick()} className={style.element}>
            <span>{label}</span>
            <div>
                <FontAwesomeIcon icon={"chevron-down"} />
            </div>
        </div>
    )
}