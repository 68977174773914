import classNames from "classnames";
import { ReactElement, useContext, useState } from "react"
import { ErrorArg, useForm, ValidationMethods } from "../hooks/UseForm";
import { LocalizationContext } from "../interfaces/AppContext";
import { EmailValidationPattern } from "../misc/Constants";
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoading from "react-loading";
import { app } from "..";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StringIsNullOrEmpty } from "../misc/Utilities";
import { useNavigate } from "react-router";
import { InputWarning } from "../components/InputWarning";
import { Link } from "react-router-dom";

import style from "../styles/login.module.scss";

interface FormModel {
    email: string;
    captchaToken: string;
    emailValidated: boolean | null;
}


enum PasswordRecoveryValidators {
    email,
    captchaToken
}

type ValidationResultCode = "0x00" | "0x01" | "0x02";

export const PasswordRecovery = (): ReactElement => {

    const lc = useContext(LocalizationContext);
    const v = lc.localization;

    const nav = useNavigate();

    const [trying, setTrying] = useState(false);


    const formValidators: ValidationMethods<FormModel>[] = [
        {
            validator: PasswordRecoveryValidators.email,
            use: (model: FormModel): ErrorArg => {

                if (StringIsNullOrEmpty(model.email))
                    return { isValid: false, message: "email" };

                return EmailValidationPattern.test(model.email) ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: "email" };
            }
        },
        {
            validator: PasswordRecoveryValidators.captchaToken,
            use: (model: FormModel): ErrorArg => {
                return model.captchaToken !== null ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: "catcha" };
            }
        }
    ];

    const { values, valueSetter, error } =
        useForm<FormModel, PasswordRecoveryValidators>({ email: "", captchaToken: null, emailValidated: null }, formValidators);


    const onSetToken = (value: string | null): void => {

        valueSetter({ name: "captchaToken", value: value });
    };

    const validateEmail = (): void => {
        setTrying(true);

        const request = axios.get(`${app.apiBasePath}/public/pwd/profilecheck?email=${values.email}`);

        request.then(result => {
            setTrying(false);


            const validationCode = result.data.code as ValidationResultCode;

            if (validationCode === "0x00") {
                // ok
                valueSetter({ name: "emailValidated", value: true })
            }
            else if (validationCode === "0x01") {
                // not found
                valueSetter({ name: "emailValidated", value: false })
            }
            else if (validationCode === "0x02") {
                // not allowed

            }
            else {
                console.warn("unexpected validation response");
            }
        });

        request.catch(e => {
            setTrying(false);
            console.error("API?");
        })

    };

    const inputValueChange = (e): void => {

        const changes = [
            { name: "email", value: e.target.value },
            { name: "emailValidated", value: null }
        ];

        valueSetter(changes);
    };

    const requestPasswordReset = (): void => {
        const request = axios.post(`${app.apiBasePath}/public/pwd/initialize`, {
            token: values.captchaToken,
            email: values.email
        });

        request.then(result => {
            nav("/resetwithcode");
        });
    };

    return (

        <div className={classNames(style.container, "page-content")}>
            <div className={classNames("form", "pwdreset", style.resetform)}>
                <h1>{v["PasswordResetPageTitle"]}</h1>

                <div className="row">
                    <div className={style.usecode}>
                        <label>{v["UserName"]}</label>
                        <Link to="/resetwithcode">{v["havePwdCode"]}</Link>
                    </div>
                    <div className={classNames(style.emailinput,
                        error(PasswordRecoveryValidators.email).isValid ? null : style.inputwarn,
                        values.emailValidated === false ? style.inputwarn : null
                    )}>

                        <input
                            onBlur={validateEmail}
                            placeholder={v["TypeEmailPlaceholder"]}
                            disabled={trying}
                            value={values.email}
                            onChange={inputValueChange}
                            type="text"
                        />


                        <div className={style.icon}>
                            {
                                values.emailValidated &&
                                <FontAwesomeIcon icon={"check"} />
                            }
                            {
                                values.emailValidated === null &&
                                error(PasswordRecoveryValidators.email).isValid &&
                                <FontAwesomeIcon className={style.chevronBtn} icon={"chevron-right"} />
                            }
                        </div>

                    </div>
                    {
                        values.emailValidated === false &&
                        <div className="row">
                            <InputWarning messageKey="emailNotFound" />
                        </div>
                    }
                </div>

                <div className="row">
                    {
                        error(PasswordRecoveryValidators.email).isValid &&
                        values.emailValidated === true &&
                        <ReCAPTCHA
                            hl={"fr"}
                            sitekey={app.captchaSiteKey}
                            onChange={onSetToken}
                        />
                    }

                </div>

                <div className={style.buttonrow}>
                    <button className="btn cancel" onClick={() => nav("/")}>
                        {v["cancel"]}
                    </button>

                    {
                        error(PasswordRecoveryValidators.email).isValid &&
                        error(PasswordRecoveryValidators.captchaToken).isValid &&
                        !trying &&
                        <button onClick={requestPasswordReset} className="btn action">
                            Continuer
                        </button>
                    }
                    {
                        trying &&
                        <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                    }
                </div>
            </div>

        </div >
    );
}