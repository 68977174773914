import classNames from "classnames";
import { ReactElement } from "react"
import style from "../styles/multiradio.module.scss";
import { Radio } from "./Radio";

export interface MultiRadioOption {
    label: string;
    value: string | number;
}

export interface MultiRadioProps {

    required?: boolean;

    options: MultiRadioOption[];

    selectedOptionValue: string | number;

    onSelect: (option: MultiRadioOption) => void;

    inline?: boolean;

    group: string;
}

export const MultiRadio = (props: MultiRadioProps): ReactElement => {


    const isValueSet = (value: string | number): boolean => {
        if (typeof value === "undefined" || value === null)
            return false;

        if(typeof value === "string" && value === "") return false;

        if(typeof value === "number" && isNaN(value)) return false;

        return true;
    }


    return (
        <div className={classNames(
            style.container,
            props.inline === true ? style.inline : null,
            props.required === true && !isValueSet(props.selectedOptionValue) ? style.required : null)}>
            {
                props.options.map((option, idx) => {
                    return (
                        <Radio
                            key={idx}
                            currentValue={props.selectedOptionValue}
                            group={props.group}
                            onChange={e => {
                                let val = e.target.value;
                                let opt = isNaN(+val) ? val : +val;
                                const selectedOpt = props.options.find(p => p.value === opt);
                                props.onSelect(selectedOpt);

                            }}
                            value={option.value}
                            label={option.label} />)
                })
            }
        </div>
    )
}