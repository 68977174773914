import { app } from "..";
import { HandleType, LocalEvent } from "../core/interfaces/CoreInterfaces";
import { AppState } from "../interfaces/AppState";
import { Message } from "../misc/EventMessages";


export class SocketMessageHandler {

    constructor() {
        this.SocketCommand.message = Message.SocketCommand;
        this.SocketEvent.message = Message.SocketEvent;
        this.SocketNotification.message = Message.SocketNotification;
    }


    SocketCommand = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        if (arg.data === "logoff") {
            window.setTimeout(()=>{
                window.location.href = `${app.apiBasePath}/auth/signout`;
               }, 1000);
        }
        else  if (arg.data === "refresh") {
           window.setTimeout(()=>{
            window.location.reload();
           }, 1000);
        }
        return true;
    });

    SocketEvent = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        return true;
    });

    SocketNotification = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        return true;
    });

}