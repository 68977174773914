import axios from "axios";
import { ReactElement, useEffect, useState, useContext } from "react"
import { app } from "../..";
import ReactLoading from "react-loading";
import { Invitation } from "../../models/Invitation";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { IsUndefinedOrNull, StringIsNullOrEmpty, stateToText } from "../../misc/Utilities";
import { InvitationEditForm } from "./InvitationEditForm";

import style from "./invitations.module.scss";
import { InvitationListView } from "./InvitationListView";
import { ReactMultiSelectStyles, ReactSelectStyles } from "../../misc/Constants";
import Select from "react-select";
import { OptionType } from "../../interfaces/OptionType";
import { InvitationState } from "../../models/Enums";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { useLocalStorage } from "../../hooks/UseLocalStorage";


export interface InvitationsProps {
    groupId?: string;
    view: string;
    adminMode?: boolean;
}

export const Invitations = (props: InvitationsProps): ReactElement => {


    const { saveToStorage, getFromStorage } = useLocalStorage();
    const { groupId, view, adminMode } = props;

    const [list, setList] = useState([] as Invitation[]);
    const [busy, setBusy] = useState(!StringIsNullOrEmpty(groupId));
    const { localGroups } = useContext(AppContext);
    const [filter, setFilter] = useState("");

    const groupOptions: OptionType[] = localGroups.map(p => { return { value: p.id, label: p.name } })
    let initOption = groupOptions.find(p => p.value === groupId);

    if (!initOption) {
        let optionFromStorage = getFromStorage("admin-invites-lastgroup");
        if (optionFromStorage) {
            initOption = optionFromStorage;
        }
    }

    const [selectedGroup, setSelectedGroup] = useState<OptionType>(initOption);
    const [stateFilters, setStateFilters] = useState([] as OptionType[]);
    const { localization } = useContext(LocalizationContext);

    const nav = useNavigate();

    const stateOptions: OptionType[] = [
        { value: InvitationState.New, label: stateToText(InvitationState.New, localization) },
        { value: InvitationState.Opened, label: stateToText(InvitationState.Opened, localization) },
        { value: InvitationState.Redeemed, label: stateToText(InvitationState.Redeemed, localization) },
        { value: InvitationState.Cancelled, label: stateToText(InvitationState.Cancelled, localization) }
    ];

    const onGroupChange = (group: OptionType): void => {
        setSelectedGroup(group);
        saveToStorage("admin-invites-lastgroup", group);
        setFilter("");
    };


    useEffect(() => {

        if (selectedGroup) {

            let path = adminMode === true ?
                `${app.apiBasePath}/admin/users/invitations/${selectedGroup.value}/`
                : `${app.apiBasePath}/groupadmin/${selectedGroup.value}/invitations`;

            const request = axios.get(path);

            request.then(r => {
                setList(r.data);
                setBusy(false);
            }).catch(x => {
                console.warn(x);
                setBusy(false);
            });
        }
        else {

        }

    }, [view, selectedGroup]);

    if (busy) {
        return (
            <div className={style.container}>
                <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
            </div>
        );
    }

    const pathToInviteUi = adminMode === true ? `/admin/invite/${selectedGroup?.value}` : `/group/manage/${selectedGroup?.value}/users/invite`;


    const invitation = list.find(p => p.uniqueShortTokenId === view);

    const filteredList = list.filter(p => {
        return p.emailAddress.toLowerCase().includes(filter.toLowerCase()) ||
            p.firstName.toLowerCase().includes(filter.toLowerCase()) ||
            p.lastName.toLowerCase().includes(filter.toLowerCase());
    });

    return (
        <div className={style.container}>
            {
                StringIsNullOrEmpty(view) &&
                <div>
                    <div style={{ margin: "0 0 15px" }}>

                        <div className={style.controls}>

                            {
                                StringIsNullOrEmpty(groupId) &&
                                <div className={style.selector}>
                                    <Select
                                        placeholder={localization["menuGroups"]}
                                        value={selectedGroup}
                                        onChange={onGroupChange}
                                        isMulti={false}
                                        options={groupOptions} />
                                </div>
                            }

                            <div className={style.selector}>
                                <input
                                    value={filter}
                                    onKeyDown={(e) => { e.key === "Escape" && setFilter(""); }}
                                    onChange={(e) => setFilter(e.target.value)}
                                    type="text" className={style.searchfield} placeholder={localization["search"]} />
                            </div>

                            <div className={style.selector}>
                                <Select
                                    placeholder="filtrer"
                                    styles={ReactMultiSelectStyles}
                                    value={stateFilters} onChange={(f) => setStateFilters(f as OptionType[])}
                                    isMulti={true}
                                    options={stateOptions} />
                            </div>

                            <div>
                                <button disabled={IsUndefinedOrNull(selectedGroup)} onClick={() => nav(pathToInviteUi)} className={classNames("btn", "action", style.invitebtn)}>
                                    <span>Inviter</span>
                                    <FontAwesomeIcon icon={"user-plus"} />
                                </button>
                            </div>
                        </div>


                    </div>
                    <InvitationListView adminMode={adminMode} list={filteredList} filterValue={stateFilters.map(s => s.value) as InvitationState[]} />
                </div>

            }
            {
                !StringIsNullOrEmpty(view) &&
                <InvitationEditForm adminMode={adminMode} invitation={invitation} />
            }
        </div>
    );
}