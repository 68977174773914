import axios from "axios";
import { Person } from "../models/Person";
import { app } from "..";



export const getUsersForLocation = async (locationId: string): Promise<Array<Person>> => {
    try {
        const result = await axios.get<Array<Person>>(`${app.apiBasePath}/admin/users/list?locationReference=${locationId}`);
        return result.data;
    }
    catch (e) {
        console.warn(e);
        return [];
    }
}