import { ReactElement, useContext, useState } from "react";

import style from "./groupselector.module.scss";
import { Group } from "../../models/Group";
import { GroupInfo } from "../../models/JoinedGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { Checkbox } from "../Checkbox";
import { isEmptyCollection } from "../../misc/Utilities";
import { isFunction } from "util";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

export interface GroupSelectorProps {
    selected?: Array<string>;
    onSelectionChange?: (collection: Array<string>) => void;
}



export const GroupSelector = (props: GroupSelectorProps): ReactElement => {

    const { selected, onSelectionChange } = props;

    const { groups, localGroups } = useContext(AppContext);

    const { localization } = useContext(LocalizationContext);

    const initialSelection = isEmptyCollection(selected) ? [] : selected;

    const [selectedGroups, setSelectedGroups] = useState<Array<string>>(initialSelection);

    const [q, setQ] = useState("");

    const alphaSort = (a: string, b: string): number => {
        return a.localeCompare(b);
    };

    const selectMyGroups = (): void => {
        setSelectedGroups(old => {
            const ids = groups.map(p => p.id);
            return [...ids];
        });
    };

    const selectAllGroups = (): void => {

        setSelectedGroups(old => {
            const ids = localGroups.map(p => p.id);
            return [...ids];
        });
    };

    const unselectAllGroups = (): void => {
        setSelectedGroups(old => {
            return [];
        });
    };


    const keywordFilter = (g: GroupInfo): boolean => {
        if (q.trim() === "") return true;
        try {
            let r = new RegExp(q, 'i');
            return r.test(g.name);
        }
        catch {
            return false;
        }
    };

    const selectGroup = (g: GroupInfo): void => {

        if (selectedGroups.some(id => id === g.id)) {
            setSelectedGroups(old => {
                const o = old.filter(id => id !== g.id);
                return [...o];
            });
        }
        else {
            setSelectedGroups(old => {
                return [...old, g.id];
            });
        }
    };

    const onApply = (): void => {
        typeof onSelectionChange === "function" && onSelectionChange(selectedGroups);
    };

    const filtered = localGroups.filter(keywordFilter);

    const delta = localGroups.length - filtered.length;

    return (
        <div className={style.control}>
            <div>

                <div className={style.quickbtns}>
                    <button onClick={selectMyGroups}>{localization["myGroups"]}</button>
                    <button onClick={selectAllGroups}>{localization["allGroups"]}</button>
                    <button onClick={unselectAllGroups}>Aucun</button>
                </div>
                <div className={style.search}>
                    <input value={q} onChange={e => setQ(e.target.value)} type="text" placeholder="chercher" />
                    <button onClick={() => setQ("")}>
                        <FontAwesomeIcon icon={"x"} />
                    </button>
                </div>
                {
                    delta > 0 &&
                    <span className={style.filteralert}>{delta} groupes sont cachés par filtre</span>
                }
            </div>
            <div className={style.overflow}>
                {

                    filtered.sort((a, b) => alphaSort(a.name, b.name))
                        .map(g => {
                            return <div key={g.id} className={classNames(style.group, groups.some(p => p.id === g.id) ? style.mine : null)}>
                                <Checkbox checked={selectedGroups.some(id => id === g.id)} onCheckChange={() => selectGroup(g)} label={g.name} />
                            </div>
                        })
                }
            </div>
            <div className={style.footer}>
                <span>{selectedGroups.length} groupes sélectionnés</span>
                <button onClick={onApply} className="btn action">Appliquer</button>
            </div>
        </div>


    );
}