import axios from "axios";
import { Group } from "../models/Group";
import { app } from "..";
import { GroupUser } from "../models/GroupUser";


export const getGroupById = async (groupId: string): Promise<Group | null> => {
    try {
        const result = await axios.get<Group>(`${app.apiBasePath}/admin/group/info?groupId=${groupId}`);
        return result.data;
    }
    catch (e) {
        console.warn(e);
        return null;
    }
}

export const getGroupUsers = async (groupId: string): Promise<Array<GroupUser>> => {
    try {
        const result = await axios.get<Array<GroupUser>>(`${app.apiBasePath}/admin/group/listusers?groupId=${groupId}`);
        return result.data;
    }
    catch (e) {
        console.warn(e);
        return [];
    }
}