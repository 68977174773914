import { ReactElement, useEffect, useState } from "react";
import { GetCountries } from "../../../misc/Requests";
import { Location } from "../../../models/Location";
import style from "./manage.module.scss";
import { Link } from "react-router-dom";

export const Manage = (): ReactElement => {



    return (
        <div className={style.container}>
            <section className={style.heading}>
                <Link to="groups">Group management</Link> | <Link to="users">User management</Link>
            </section>
            <div className={style.content}>
 
            </div>
        </div>
    );
}