import { ReactElement, useContext } from "react";
import { app } from "..";
import { Link } from "react-router-dom";
import { LocalizationContext } from "../interfaces/AppContext";

import style from "../styles/footer.module.scss";


export const Footer = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    
    return (
        <footer className={style.container}>
            <span>build {app.build}</span>
            <div>
                <Link to="/codeofconduct">{localization["codeOfConduct"]}</Link>
            </div>
        </footer>
    )
}