import axios from "axios";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import { app } from "../..";
import { StatusCodes } from "http-status-codes";
import { Invitation } from "../../models/Invitation";
import ReactLoading from "react-loading";
import { InvitationState } from "../../models/Enums";
import { Link } from "react-router-dom";
import style from "./joingroup.module.scss";

export const JoinGroup = (): ReactElement => {

    const { code } = useParams();

    const [invitation, setInvitation] = useState<Invitation>(null);
    const [working, setWorking] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {

        const invitation = axios.get(`${app.apiBasePath}/profile/invitation?code=${code}`);

        invitation.then(r => {
            if (r.status === StatusCodes.OK) {
                setInvitation(r.data);
            }
        })

    }, [code]);


    if (invitation == null) {
        return <ReactLoading type={"spin"} color={"#5270F0"} height={28} width={28} />
    }

    if (invitation.state === InvitationState.Cancelled ||
        invitation.state === InvitationState.Redeemed) {
        return (
            <div className={style.container}>
                <h3>Code expiré</h3>
            </div>
        );
    }

    const redeem = (): void => {
        setWorking(true);

        axios.post(`${app.apiBasePath}/profile/redeem`, {
            code: code
        }).then(r => {

            if (r.status === StatusCodes.OK) {
                // refresh the group list
                setWorking(false);
                setSuccess(true);
            }
        });
    };

    if (success) {
        return (
            <div className={style.container}>
                <h3>Bravo! Vous venez de joindre {invitation.groupName}</h3>
                <p>À partir de maintenant, vous pouvez  <Link to={"/post/exchange"}>placer des annonces</Link> dans cette groupe.</p>
                <Link className="btn action" to="/">Terminer</Link>
            </div>
        )
    }

    return (
        <div className={style.container}>
            <p>{invitation.createdByName} vous invite à joindre le groupe <strong>{invitation.groupName}</strong></p>
            <div className={style.controls}>
                {
                    !working &&
                    <button onClick={redeem} className="btn action">Accepter</button>

                }

                {
                    working &&
                    <>
                        <ReactLoading type={"spin"} color={"#5270F0"} height={28} width={28} />
                        <span>Rejoindre {invitation.groupName}...</span>
                    </>
                }
            </div>
        </div>
    )
}