import React, { PropsWithChildren, ReactElement } from 'react';
import style from '../styles/notification.module.scss';
import classnames from 'classnames';

export enum NotificationType{
    Info,
    Warning,
    Error
}
export interface NotificationProps extends PropsWithChildren  {    
    type: NotificationType;
}

const NotificationView = (props: NotificationProps) : ReactElement => {

    let css = classnames(style.notice, style.info);

    if(props.type === NotificationType.Warning){
        css = classnames(style.notice, style.warn);
    }
    if(props.type === NotificationType.Error){
        css = classnames(style.notice, style.error);
    }
    return <div className={css}>{props.children}</div>;
}

export {NotificationView}