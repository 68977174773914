import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Menu } from '../../components/Menu';
import { MobileMenu } from '../../components/MobileMenu';
import { NavItem } from '../../interfaces/NavItem';
import { Footer } from '../../components/Footer';
import style from "./master.module.scss";

export interface MasterProps {
    navigation: NavItem[];
    mobileMenuVisible: boolean;
    onOpenMobileMenu: () => void;
    onCloseMobileMenu: () => void;
}

const Master = (props: MasterProps): JSX.Element => {

    const { onOpenMobileMenu, mobileMenuVisible, onCloseMobileMenu, navigation } = props;


    return (
        <React.Fragment>
            <Header
                hideNotifications={true}
                showMobileMenu={onOpenMobileMenu}
            />

            <MobileMenu
                visible={mobileMenuVisible}
                close={onCloseMobileMenu}
                links={navigation} />

            <div className={style.container}>
                <div className={style.viewport}>
                    <div className={style.split}>
                        <div className={style.left}>
                            <Menu links={navigation} />
                        </div>
                        <div className={style.center}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </React.Fragment>

    );

}

export { Master }