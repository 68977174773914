import { ReactElement } from "react";
import style from "./accountconfirm.module.scss";
import { Link } from "react-router-dom";

export const ActivationFailure = (): ReactElement => {

    return (
        <div className={style.container}>
            <h1>Quelque chose n'a pas fonctionné :(</h1>

            <div className={style.home}>
                <Link to="/">Page d'acceuil</Link>
            </div>
        </div>
    )
};