import * as React from 'react';
import classnames from 'classnames';
import { IsNullOrWhiteSpace } from '../misc/Utilities';

import style from '../styles/checkbox.module.scss';

export interface CheckboxProps {
    checked: boolean;
    onCheckChange: Function;
    disabled?: boolean;
    label: string;
    cssClass?: string;
}

const Checkbox = (props: CheckboxProps) : JSX.Element => {

    let css = style.container;

    if (!IsNullOrWhiteSpace(props.cssClass)) {
        css = classnames(css, props.cssClass);
    }

    let checkCss = style.checkmark;

    if (props.checked === true) {
        checkCss = classnames(style.checkmark, style.checked);
    }
    else if (props.checked === false) {
        checkCss = classnames(style.checkmark, "ico-x-red-16", style["false-checked"]);
    }
 
    return (

            <div className={style.wrapper}>
                <div className={props.disabled === true ? classnames(css, "disabled") : css}
                    onClick={props.onCheckChange.bind(this)}>

                    <div className={checkCss}>
                        <pre></pre>
                    </div>

                    {
                        props.checked === true &&
                        <label className={style.checked}>{props.label}</label>
                    }
                    {
                        props.checked === false &&
                        <label className={style["false-checked"]}>{props.label}</label>
                    }

                    {
                        props.checked === null &&
                        <label>{props.label}</label>
                    }
                </div>
            </div>

    );
}

export { Checkbox }