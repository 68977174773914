import React, { useEffect, useState } from 'react';
import pl from 'plupload';
import { StorageType } from '../models/Enums';
import { IUploadForm } from '../interfaces/IUploadForm';
import { PubSubTopic } from '../misc/Constants';
import { IsNullOrWhiteSpace } from '../misc/Utilities';
import cuid from 'cuid';

export enum SupportedFiles {
    Images,
    ImagesAndPDF,
    PDF,
    Unrestricted
}

export interface UploaderFile{
    name?: string;
    size? : number;
    type?: string;
}

export interface UploadFormBase {
    storageType: StorageType;
}

export interface UploaderProps {
    url: string;
    multiSelect?: boolean;
    maxFileSizeMB?: number;
    supportedFiles: SupportedFiles;
    buttonText?: string;
    monitorDispach?: boolean;
    autoUpload?: boolean;
    onUploadProgress?: (file: any) => void;
    onFileUploaded?: (data: UploadResponse) => void;
    onUploadComplete?: (files: any[]) => void;
    onBeforeUpload?: (file: any) => void;
    onError?: (error: UploaderError) => void;
    uploadMetadata: UploadFormBase;
    progressBarId?: string;
}
export interface UploadResponse {
    accepted: boolean;
    fileId?: string;
    errorCode?: number;
    form?: IUploadForm;
}
export interface UploaderError {
    code: number;
    message: string;
    file? : UploaderFile;
}
interface UploadResult {
    response: string,
    status: number,
    responseHeaders: string;
}


const Uploader = (props: UploaderProps): JSX.Element => {

   
  

    const GetFileFilterFromSupportedfiles = (opt: SupportedFiles): plupload_filters_mime_types => {
        switch (opt) {
            case SupportedFiles.Images:
                return { title: "Image files", extensions: "jpeg,jpg,png" };
            case SupportedFiles.ImagesAndPDF:
                return { title: "Image files", extensions: "jpeg,jpg,png,pdf" };
            case SupportedFiles.PDF:
                return { title: "Image files", extensions: "pdf" };

            case SupportedFiles.Unrestricted:
                return null;
            default:
                throw new TypeError("option not supported: " + opt);
        }
    };
    
 

    let uploader: pl.Uploader = null;


    const onMessageRecevied = (msg, data): void => {
        if (data.event === "uploader-browse") {
            document.getElementById(browseButtonId).click();
        }
        else if(data.event === "uploader-start" && uploader !== null){
            uploader.start();
        }
    };

    const [container] = useState(() => {
        const c = document.createElement("div");
        return c;
    });

    const [browseButtonId, setBrowseButtonId] = useState(cuid());
    
    useEffect(() => {
        const instanceId = cuid();
       // const browseButtonId = cuid();
        container.id = instanceId;

        document.body.prepend(container);

        const settings: plupload_settings = {
            url: '',
            runtimes: 'html5',
            multipart: true,
            multipart_params: props.uploadMetadata,
            multi_selection: props.multiSelect === true,
            container: instanceId,
            browse_button: browseButtonId,
            required_features: { send_browser_cookies: true },
            filters: {
                max_file_size: '20mb',
                mime_types: []
            },
            init: {
                PostInit: (uploader: plupload.Uploader) => {
                    // init click
    
                },
    
                Error: (uploader: plupload.Uploader, error: UploaderError) => {
                    if (typeof props.onError === "function") {
                        props.onError(error);
                    }
                },
    
                FilesAdded: (uploader: plupload.Uploader, files: any[]) => {
                    if (props.autoUpload !== false) {
                        uploader.start();
                    }
                },
    
                UploadProgress: (uploader: plupload.Uploader, file: any) => {
                    if (typeof props.onUploadProgress === "function") {
                        props.onUploadProgress(file);
                    }
                    if (!IsNullOrWhiteSpace(props.progressBarId)) {
                        let dom = document.getElementById(props.progressBarId);
                        if (dom !== null) {
                            dom.style.width = file.percent + '%';
                        }
                    }
                },
    
                FileUploaded: (uploader: plupload.Uploader, file, result: UploadResult) => {

                    if (!IsNullOrWhiteSpace(props.progressBarId)) {
                        let dom = document.getElementById(props.progressBarId);
                        if (dom !== null) {
                            dom.style.width = '0%';
                        }
                    }
                    
                    if (typeof props.onFileUploaded === "function") {
                        try {
                            props.onFileUploaded(JSON.parse(result.response));
                        }
                        catch {
                            console.warn(result.response);
                        }
    
                    }
                },
    
                UploadFile: (uploader: plupload.Uploader, file: any) => {
                    if (typeof props.onBeforeUpload === "function") {
                        props.onBeforeUpload(file);
                    }
                },
    
                UploadComplete: (uploader: plupload.Uploader, files: any[]) => {
                    if (typeof props.onUploadComplete === "function") {
                        props.onUploadComplete(files);
                    }
                }
            }
        };



        let broakerRef = "";

        if (props.monitorDispach === true) {
            broakerRef = PubSub.subscribe(PubSubTopic.Component + ".uploader", onMessageRecevied);
        }

        if (IsNullOrWhiteSpace(props.url)) {
            throw new TypeError("URL is empty");
        }

        settings.url = props.url;

        if (!isNaN(props.maxFileSizeMB)) {
            let maxSize = props.maxFileSizeMB;
            if (maxSize < 1) maxSize = 1;
            settings.filters.max_file_size = maxSize + 'mb';
        }

        let mime = GetFileFilterFromSupportedfiles(props.supportedFiles);
        if (mime !== null) {
            settings.filters.mime_types.push(mime);
        }

        uploader = new pl.Uploader(settings);
        uploader.init();

        return () => {

            document.body.removeChild(container)

            if (props.monitorDispach === true) {
                PubSub.unsubscribe(broakerRef);
            }
            if (uploader !== null) {
                uploader["removeAllEventListeners"]();
                uploader.destroy();
            }
        }
    }, []);

 
    return (
        <div>
            {
                !IsNullOrWhiteSpace(props.buttonText) &&
                <button id={browseButtonId} className="btn action">{props.buttonText}</button>
            }
            {
                IsNullOrWhiteSpace(props.buttonText) &&
                <div id={browseButtonId}></div>
            }
        </div>
    );
}

export { Uploader }