import { ReactElement, useState } from "react";
import style from "./test.module.scss";
import axios from "axios";
import { app } from "../..";
import { isValidEmailAddress } from "../../misc/Utilities";

export const Tests = (): ReactElement => {


    const [email, setEmail] = useState("");
    const [working, setWorking] = useState(false);


    const send = () : void =>{
        setWorking(true);

        axios.post(`${app.apiBasePath}/diag/sendemail?address=${email}`)
        .then(r=>{
            setWorking(false);
        });
    }

    const validEmail = isValidEmailAddress(email);

    return <div className={style.container}>
        <h1>Run Tests</h1>

        <div className={style.testform}>
            <div className={style.a}>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="type email" />
            </div>
            <div className={style.b}>
                <button disabled={working || !validEmail} onClick={send} className="btn action">Send test email</button>
            </div>
        </div>
    </div>
};