import '../styles/responsive/form.css';
import * as axios from 'axios';
import { NotificationView, NotificationType } from '../components/NotificationView';
import { IsNullOrWhiteSpace } from '../misc/Utilities';
import { LocalizationContext } from '../interfaces/AppContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from "../styles/login.module.scss";
import { app } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

type PwdType = "password" | "text";


export interface LoginProps {
    redirectTo?: string;
}

export const Login = (props: LoginProps): ReactElement => {

    const { redirectTo } = props;


    const [username, setUsername] = useState("");
    const [pwd, setPwd] = useState("");
    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState<string>(null);
    const [trying, setTrying] = useState(false);


    const [pwdType, setPwdType] = useState<PwdType>("password");

    const [redirecting, setRedirecting] = useState(false);

    const onLoginClick = (): void => {
        setTrying(true);

        let result = axios.default.post(`${app.apiBasePath}/auth/login`, {
            username: username,
            password: pwd
        });

        result.then(d => {

            setTrying(false);

            if (!IsNullOrWhiteSpace(d.data.error)) {
                if (d.data.error === "login-fail") {
                    setError(v["AuthFailureMsg"]);
                }
                else if (d.data.error === "email-confirm") {
                    setError(v["EmailConfirmRequired"]);
                }
                if (!IsNullOrWhiteSpace(d.data.code)) {
                    setErrorCode(d.data.code);
                }
                else {
                    setErrorCode(null);
                }

            }
            else {
                setRedirecting(true);
                setTimeout(() => window.location.href = redirectTo, 300);
            }
        });

        result.catch(e => {
            setTrying(false);
            console.warn(e);
        });

    };

    const onKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === "Enter") {
            if (username.length > 0 && pwd.length > 0) {
                onLoginClick();
            }
        }
    }


    let loginDisabled = IsNullOrWhiteSpace(pwd) || IsNullOrWhiteSpace(username);

    const { localization } = useContext(LocalizationContext);
    const v = localization;

    const togglePasswordVisibility = (): void => {
        if (pwdType === "text") {
            setPwdType("password");
        }
        else setPwdType("text");
    }

    if (redirecting) {
        return (
            <div className="page-content">
                <div className={style.ok}>
                    <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                </div>
            </div>
        );
    }



    return (
        <div className="page-content">
            <div className={classNames("form", style.login)}>
                <form>
                    <div className="row">
                        <label>{v["UserName"]}</label>
                        <input
                            autoComplete="username"
                            disabled={trying}
                            onKeyDown={onKeyDown.bind(this)}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            className="form-control" />
                    </div>
                    <div className="row">
                        <div className={style.labelhelp}>
                            <label>{v["Password"]}</label>
                            <Link to="/resetpwd">{v["ForgotPwd"]}</Link>
                        </div>
                        <div className={style.pwdfield}>
                            <input
                                disabled={trying}
                                onKeyDown={onKeyDown.bind(this)}
                                autoComplete="current-password"
                                type={pwdType}
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                            />
                            {
                                pwdType === "password" &&
                                <FontAwesomeIcon onClick={togglePasswordVisibility} icon={faEye} />
                            }
                            {
                                pwdType === "text" &&
                                <FontAwesomeIcon onClick={togglePasswordVisibility} icon={faEyeSlash} />
                            }
                        </div>
                    </div>


                    <div className={style.buttonrow}>
                        {
                            !trying &&
                            <React.Fragment>
                                <button disabled={loginDisabled} className="btn action" onClick={onLoginClick}>
                                    <span>{v["LoginButton"]}</span>
                                </button>

                                <Link className={classNames("btn", "signup", style.signupbtn)} to="/bienvenue">{v["NewAccount"]}</Link>
                            </React.Fragment>
                        }
                        {
                            trying &&
                            <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                        }

                    </div>

                    {
                        error !== '' &&
                        <div className={style.warning}>
                            <NotificationView type={NotificationType.Warning}>
                                <p>{error}</p>
                                {
                                    errorCode !== null &&
                                    <p style={{ marginLeft: "15px", color: "#D81A59" }}>{v[errorCode]}</p>
                                }
                            </NotificationView>
                        </div>
                    }
                </form>
            </div>
        </div >
    );

}

