import React from 'react';
import { PersonStatus, Role } from '../models/Enums';
import { FavoriteExchange } from '../models/FavoriteExchange';
import { Group } from '../models/Group';
import { Person } from '../models/Person';
import { Dictionary } from './Dictionary';
import { GroupPath } from '../models/GroupPath';
import { GroupInfo } from '../models/JoinedGroup';


export type AppContextType = {
    person: Person;
    groups: Array<Group>;
    localGroups: Array<GroupInfo>;
    groupPath: Array<GroupPath>;
    favorites: Array<FavoriteExchange>;
};

export const AppContext = React.createContext<AppContextType>({
    person: {
        personId: '',
        dateCreated: '',
        status: PersonStatus.New,
        role: Role.None,
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        primaryNumber: '',
        secondaryNumber: '',
        timeZone: -5,
        avatarFileId: ''
    },
    groups: [],
    localGroups: [],
    groupPath: [],
    favorites: []
});


export type LocalizationContextType = {
    localization: Dictionary<string>;
};

export const LocalizationContext = React.createContext<LocalizationContextType>({ localization: {} });