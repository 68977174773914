import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactLoading from "react-loading";
import style from "./usermanage.module.scss";
import { Person } from "../../../models/Person";
import React from "react";
import axios from "axios";
import { app } from "../../..";
import { StatusCodes } from "http-status-codes";
import { RssFeed } from "@mui/icons-material";

export const UserLoader = (props: any): ReactElement => {

    const params = useParams();
    const id = params["personId"];

    const [person, setPerson] = useState<Person>(null);
    const [groups, setGroups] = useState<string[]>([]);

    useEffect(() => {


        axios.get(`${app.apiBasePath}/admin/users/getprofile?personId=${id}`).then(result => {
            if (result.status === StatusCodes.OK) {
                setPerson(result.data.person);

                let groups = [];
                if(Array.isArray(result.data.groups)){
                    groups = result.data.groups.map(g=>g.group.id);
                }
                setGroups(groups);
            }
        });



    }, [id])

    if (person === null)
        return <div className={style.container}><ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} /></div>

    else return React.Children.map(props.children, (child, index: number) => {
        return React.cloneElement(child, { ...{ person: person, joinedGroups : groups }, ...props });
    })
}