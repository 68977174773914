import { ReactElement, useContext } from "react"
import { Link } from "react-router-dom";
import { LocalizationContext } from "../../interfaces/AppContext";
import style from "./style.module.scss";

export const NoContentPage = ({ children }: { children?: any }): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    if (children) {
        return <div className={style.container}>
            {
                children
            }
        </div>
    }

    return (
        <div className={style.container}>
            <h2>{localization["nocontent"]}</h2>
            <Link to="/">{localization["return"]}</Link>
        </div>
    );
}