
export const generatePageNumbers = (currentPage: number, maxPagesToGenerate: number, totalNumberOfPages: number): number[] => {
    const subset: number[] = [];
    const startcurrentPage = Math.max(1, currentPage - Math.floor(maxPagesToGenerate / 2));
    const endcurrentPage = Math.min(startcurrentPage + maxPagesToGenerate, totalNumberOfPages + 1);

    if (endcurrentPage - startcurrentPage < maxPagesToGenerate) {
        for (let i = endcurrentPage - maxPagesToGenerate; i < endcurrentPage; i++) {
            subset.push(i);
        }
        return subset;
    }

    for (let i = startcurrentPage; i < endcurrentPage; i++) {
        subset.push(i);
    }

    return subset;
};