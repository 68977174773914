import { ReactElement, useContext } from "react";

import style from "./myexchanges.module.scss";
import { Exchange } from "../../models/Exchange";
import { Category } from "../../models/Category";
import { LocalizationContext } from "../../interfaces/AppContext";

import common from '../common.module.scss';
import { IsNullOrWhiteSpace, dateSortDesc } from "../../misc/Utilities";
import classNames from "classnames";
import moment from "moment";
import { AttachmentType } from "../../models/Enums";
import { app } from "../..";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/EventMessages";
import { Link } from "react-router-dom";
import noimg from "../../images/no-image.jpg";

export interface MyExchangesProps {
    titleKey: string;
    noItemsKey: string;
    exchanges: Exchange[];
    categories: Category[];
}

const ExchangeComponent = ({ exchange }: { exchange: Exchange }): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const vocab = localization;


    const nav = useNavigate();


    const files = exchange.attachments.filter(p => p.attachmentType === AttachmentType.Image);
    let fileId = "";
    if (files.length > 0) {
        fileId = files[0].fileId;
    }

    const onDeleteDraft = (exchange: Exchange): void => {
        if (window.confirm(`${vocab["confimDelete"]}: ${exchange.title}`)) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.DeleteDraft,
                data: {
                    id: exchange.id
                }
            });
        }
    };

    const viewExchange = (): void => {
        nav(`/exchanges/${exchange.id}`);
    };

    return (
        <div onClick={viewExchange} className={classNames(style.exchange, exchange.exchangeType === "demande" ? style.demande : null)}>
            <div className={style.header}>
                <div>
                    {
                        IsNullOrWhiteSpace(exchange.title) &&
                        <h4>{localization["noTitle"]}</h4>
                    }
                    {
                        !IsNullOrWhiteSpace(exchange.title) &&
                        <h4>{exchange.title}</h4>
                    }

                    <div>
                        <span className={style.when}>{localization["exchangePosted"]} {
                            moment(exchange.publishedOn).fromNow()
                        }</span>
                    </div>
                </div>
                <div className={style.controls}>
                    <button onClick={(e) => { e.stopPropagation(); onDeleteDraft(exchange); }} className={style.delete}>
                        <FontAwesomeIcon icon="trash" />
                    </button>
                    <button className={style.edit} onClick={(e) => { e.stopPropagation(); nav(`/post/exchange/${exchange.id}`); }}>
                        <FontAwesomeIcon icon="pen" />
                    </button>
                </div>
            </div>


            {
                IsNullOrWhiteSpace(fileId) &&
                <div className={style.thumb}>
                    <img src={noimg} />
                </div>
            }
            {
                fileId !== "" &&
                <div className={style.thumb}>
                    <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${fileId}`} alt="image non trouvé" />
                </div>
            }

        </div>
    );
}



export const MyExchanges = (props: MyExchangesProps): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const vocab = localization;

    const { titleKey, exchanges } = props;

    return (
        <div className={classNames(common.container, style.container)}>
            <div className={common.heading}>
                <h1>{vocab[titleKey]}</h1>
            </div>
            <div className={style.list}>
                {
                    exchanges.length === 0 &&
                    <div>
                        <p>{localization["noResults"]}</p>
                        <div style={{ marginTop: "10px" }}>
                            <Link to="/post/exchange">{vocab["publishExchange"]}</Link>
                        </div>
                    </div>

                }
                {
                    exchanges.sort((a, b) => dateSortDesc(a.publishedOn, b.publishedOn)).map(exc => {

                        return <ExchangeComponent key={exc.id} exchange={exc} />;
                    })
                }
            </div>
        </div>
    )


};