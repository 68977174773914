import { Dictionary } from "../interfaces/Dictionary";
import { NavItem } from "../interfaces/NavItem";
import { HasPrivilege } from "../misc/Utilities";
import { ExchangeState, GroupPrivileges, Role } from "../models/Enums";
import { FavoriteExchange } from "../models/FavoriteExchange";
import { Group } from "../models/Group";
import { Exchange } from "../models/Exchange";

export const HomeMenuOptions = (): NavItem => {

    return {
        label: "Recherche",
        fontAwesomeName: "search",
        color: "#2f688d",
        visible: true,
        disabled: false,
        path: "/",
        items: null
    }
};

export const MyExchangesMenuOptions = (exchanges: Exchange[], vocabulary: Dictionary<string>, favorites: FavoriteExchange[]): NavItem => {
    return {
        label: vocabulary["menuExchanges"],
        iconClass: "",
        disabled: false,
        visible: true,
        fontAwesomeName: "arrow-right-arrow-left",
        color: '#3b82b1',
        items: [
            {
                label: `${vocabulary["myDrafts"]} (${exchanges.filter(e => e.state === ExchangeState.Draft).length})`,
                fontAwesomeName: "pen",
                color: '#284682',
                visible: true,
                disabled: false,
                path: "/drafts"
            },
            {
                label: `${vocabulary["myOffers"]} (${exchanges.filter(e => e.state === ExchangeState.Published && e.exchangeType === "offer").length})`,
                visible: true,
                fontAwesomeName: "hand-holding-heart",
                color: '#284682',
                disabled: false,
                path: "/exchanges"
            },
            {
                label: `${vocabulary["myDemands"]} (${exchanges.filter(e => e.state === ExchangeState.Published && e.exchangeType === "demande").length})`,
                visible: true,
                fontAwesomeName: "hand-point-up",
                color: '#284682',
                disabled: false,
                path: "/demandes"
            },
            /* {
                 label: `${vocabulary["mySubscriptions"]}`,
                 visible: true,
                 fontAwesomeName: "bell",
                 color: "D81A59",
                 disabled: false,
                 path: "/subscriptions"
             },*/
            {

                label: `${vocabulary["myFavotires"]} (${favorites.length})`,
                visible: true,
                fontAwesomeName: "star",
                color: "#F1931B",
                disabled: false,
                path: "/favorites"
            }
        ]
    }
};

export const ModeratorMenuOptions = (groups: Group[]): NavItem | null => {

    const menuItems: NavItem[] = groups
        .filter(g => HasPrivilege(g.privilege, GroupPrivileges.Facilitator))
        .map(g => {
            return {
                label: g.name,
                fontAwesomeName: "user-group",
                path: "/group/manage/" + g.id + "/users",
                disabled: false,
                visible: true
            }
        });

    if (menuItems.length > 0) {
        return {
            label: "Gestion",
            iconClass: "ico-bolt-16",
            disabled: false,
            visible: true,
            items: menuItems
        }
    }
    else return null;
};

export const DevMenuOptions = (): NavItem => {
    const logs: NavItem = {
        label: "Logs",
        fontAwesomeName: "list-check",
        color: "#2f688d",
        path: "/dev/logs",
        disabled: false,
        visible: true
    };

    const debugs: NavItem = {
        label: "Debug User",
        fontAwesomeName: "person-circle-exclamation",
        color: "#f4581a",
        path: "/dev/userprofile",
        disabled: false,
        visible: true
    };

    const tests: NavItem = {
        label: "Tests",
        fontAwesomeName: "check",
        color: "#38b70e",
        path: "/dev/test",
        disabled: false,
        visible: true
    };


    return {
        label: "Developer",
        fontAwesomeName: "code",
        color: "#2f688d",
        disabled: false,
        visible: true,
        items: [logs, debugs, tests]
    }
};

export const AdminMenuOptions = (userPrivileges: Role, localization: Dictionary<string>): NavItem => {


    let adminMenu: NavItem = {
        label: "Administration",
        fontAwesomeName: "list-check",
        color: "#2f688d",
        visible: true,
        disabled: false,
        path: "",
        items: []
    };

    const userManageItem: NavItem = {
        fontAwesomeName: "lock",
        color: "#2f688d",
        label: "Accès",
        path: "/admin/users",
        visible: true,
        disabled: false
    };

    const userInviteManageItem: NavItem = {
        fontAwesomeName: "envelope",
        color: "#2f688d",
        label: localization["invited"],
        path: "/admin/invitations",
        visible: true,
        disabled: false
    };

    const groupManageItem: NavItem = {
        fontAwesomeName: "user-group",
        color: "#2f688d",
        label: localization["menuGroups"],
        path: "/admin/groups",
        visible: true,
        disabled: false
    };

    const categoryManage: NavItem = {
        label: localization["menuCategories"],
        fontAwesomeName: "filter",
        color: "#2f688d",
        path: "/admin/categories",
        disabled: false,
        visible: true
    };
    const vocabularyManage: NavItem = {
        label: localization["menuVocabulary"],
        fontAwesomeName: "file-lines",
        color: "#2f688d",
        path: "/admin/vocabulary",
        disabled: false,
        visible: true
    };

    if (HasPrivilege(userPrivileges, Role.Developper)) {
        adminMenu.items.push(userManageItem);
        adminMenu.items.push(userInviteManageItem);
        adminMenu.items.push(groupManageItem);
        adminMenu.items.push(categoryManage);
        adminMenu.items.push(vocabularyManage);
    }
    else {
        if (HasPrivilege(userPrivileges, Role.UserManagement)) {
            adminMenu.items.push(userManageItem);
            adminMenu.items.push(userInviteManageItem);
        }
        if (HasPrivilege(userPrivileges, Role.GroupManagement)) {

            adminMenu.items.push(groupManageItem);
        }
        if (HasPrivilege(userPrivileges, Role.CategoryManagement)) {

            adminMenu.items.push(categoryManage);
        }
        if (HasPrivilege(userPrivileges, Role.VocabularyManagement)) {

            adminMenu.items.push(vocabularyManage);
        }
    }

    return adminMenu;
}