import { ReactElement, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ReactLoading from "react-loading";
import { GroupUser } from "../../../models/GroupUser";
import { HasPrivilege } from "../../../misc/Utilities";
import { GroupPrivileges } from "../../../models/Enums";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { getGroupById, getGroupUsers } from "../../../requests/groupRequests";
import { groupKeys } from "../../../queryKeys/groupKeys";
import { deleteGroup, makeFacilitator, removeFacilitator, renameGroup } from "../../../requests/groupManagement";
import style from "./groupmanage.module.scss";

export const ManageGroupForm = (): ReactElement => {

    const queryClient = useQueryClient();
    const { groupId } = useParams();
    const nav = useNavigate();
    const { localization } = useContext(LocalizationContext);

    const [isEdit, setIsEdit] = useState(false);
    const [tempGroupName, setTempGroupName] = useState("");

    const [{ data: group, isLoading: groupsLoading }, { data: people, isLoading: usersLoading }] = useQueries({
        queries: [
            {
                queryFn: () => getGroupById(groupId),
                queryKey: groupKeys.byId(groupId),
                staleTime: 1000 * 30,
            },
            {
                queryFn: () => getGroupUsers(groupId),
                queryKey: groupKeys.groupUsers(groupId),
                staleTime: 1000 * 30,
            }
        ]
    });

    const groupRename = useMutation({
        mutationFn: () => renameGroup(groupId, tempGroupName),
        onSuccess: () => {
            setIsEdit(false);
            queryClient.invalidateQueries({
                queryKey: groupKeys.byId(groupId)
            });
        }
    });

    const makeFacilitatorHandler = useMutation({
        mutationFn: (user: GroupUser) => makeFacilitator(groupId, user),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: groupKeys.groupUsers(groupId)
            });
        }
    });

    const removeFacilitatorHandler = useMutation({
        mutationFn: (user: GroupUser) => removeFacilitator(groupId, user),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: groupKeys.groupUsers(groupId)
            });
        }
    });

    const deleteGroupHandler = useMutation({
        mutationFn: () => deleteGroup(groupId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: groupKeys.byId(groupId)
            });
            nav("/admin/groups");
        }
    });

    const deleteGroupCheck = (): void => {
        if (window.confirm(`Supprimer ${group.name}?`)) {
            deleteGroupHandler.mutate();
        }
    };

    const editGroupTitle = (): void => {
        setTempGroupName(group.name);
        setIsEdit(true);
    };

    const validGroupName = (value: string): boolean => {
        return value.trim().length >= 3;
    }

    if (groupsLoading || usersLoading || deleteGroupHandler.isPending) {
        return <ReactLoading type={"spin"} color={"#5270F0"} height={28} width={28} />
    }

    return (
        <div className={style.container}>
            <div className="form">
                {
                    group !== null &&
                    <div className="row">
                        {
                            !isEdit &&
                            <div className={style.groupname}>
                                <h2>{group.name}</h2>
                                <button onClick={editGroupTitle}>
                                    <FontAwesomeIcon icon={"pen"} />
                                </button>
                            </div>
                        }
                        {
                            isEdit &&
                            <div className={style.groupnameedit}>
                                <h2>Renommer {group.name}</h2>
                                <input
                                    disabled={groupRename.isPending}
                                    onKeyDown={key => key.code === "Escape" && setIsEdit(false)}
                                    type="text"
                                    maxLength={120}
                                    className="form-control"
                                    value={tempGroupName}
                                    onChange={e => setTempGroupName(e.target.value)}
                                />

                                <div className={style.buttons}>
                                    <button disabled={groupRename.isPending} onClick={() => setIsEdit(false)} className="btn cancel">{localization["cancel"]}</button>
                                    <button disabled={!validGroupName(tempGroupName) || groupRename.isPending} onClick={() => groupRename.mutate()} className="btn save">{localization["save"]}</button>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    people.length === 0 &&
                    !isEdit &&
                    <button onClick={() => deleteGroupCheck()} className="btn cancel">{localization["delete"]?.toLowerCase()} {group.name}</button>
                }
                <div className={style.list}>
                    {
                        people.map(p => {
                            return (
                                <div className={style.user} key={p.personId}>
                                    <div className={style.ug}>
                                        <span>{p.displayName}</span>

                                        {
                                            !HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <a onClick={() => makeFacilitatorHandler.mutate(p)}>{localization["promoteLabel"]}</a>
                                        }
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <a className={style.remove} onClick={() => removeFacilitatorHandler.mutate(p)}>retirer</a>
                                        }
                                    </div>
                                    <div className={style.email}>
                                        <span>{p.emailAddress}</span>
                                    </div>
                                    <div className={style.tags}>
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <span className={style.g}>{localization["facilitatorLabel"]}</span>
                                        }
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.CanPost) &&
                                            <span>{localization["publish"]?.toLowerCase()}</span>
                                        }

                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.CanSearch) &&
                                            <span>{localization["search"]?.toLowerCase()}</span>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};