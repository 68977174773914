import classNames from "classnames";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";

import style from "./usermanage.module.scss";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { Checkbox } from "../../../components/Checkbox";
import Select, { MultiValue } from "react-select";
import { GetGroups } from "../../../misc/Requests";
import { OptionType } from "../../../interfaces/OptionType";
import { generate } from "generate-password-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsNullOrWhiteSpace, isValidEmailAddress } from "../../../misc/Utilities";
import axios from "axios";
import { app } from "../../..";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router";


export type ValidatorType = "email" | "firstName";
export type ValidatorFn = (value: string) => boolean;
export type Validator = {
    type: ValidatorType;
    valid: null | boolean;
    fn: ValidatorFn;
    msg?: string;
};

export const UserForm = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const [isUpdating, setIsUpdating] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [hasUserManage, setHasUserManage] = useState(false);
    const [hasGroupManage, setHasGroupManage] = useState(false);
    const [hasCategoryManage, setHasCategoryManage] = useState(false);
    const [qa, setQA] = useState(false);
    const [vocab, setVocab] = useState(false);

    const [groups, setGroups] = useState<OptionType[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<MultiValue<OptionType>>([]);

    const [password, setPassword] = useState("");

    const pwdRef = useRef();


    const nav = useNavigate();

    const validatorsOptions: Validator[] = [
        {
            type: "email",
            valid: false,
            fn: (value: string): boolean => isValidEmailAddress(value)
        },
        {
            type: "firstName",
            valid: false,
            fn: (value: string): boolean => !IsNullOrWhiteSpace(value)
        }
    ];

    const [validators, setValidators] = useState(validatorsOptions);


    const runValidator = (value: string, validator: ValidatorType): void => {

        const m = validators.map(v => {
            if (v.type === validator) {
                v.valid = v.fn(value);
                v.msg = "";
            }
            return v;
        });

        setValidators(old => {
            return [...m];
        });
    }

    useEffect(() => {

        generateNewPassword();

        const result = GetGroups(email);
        result.then(groups => {
            if (groups !== null) {

                const selectOptions = groups.map(g => {
                    const p: OptionType = {
                        value: g.id,
                        label: g.name
                    };
                    return p;
                })
                setGroups(selectOptions);

            }
        });

    }, []);


    const generateNewPassword = (): void => {
        const pwd = generate({
            length: 9,
            numbers: true,
            strict: true
        });
        setPassword(pwd);
    };

    const onGroupSelectionChange = (options: MultiValue<OptionType>): void => {
        setSelectedGroups(options);
    };

    const onPwdFiledClick = (): void => {
        const html = pwdRef.current as HTMLInputElement;
        html?.select();
    };

    const submitUserCreate = (): void => {
        setIsUpdating(true);

        const model = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            joinGroups: selectedGroups.map(p => p.value),
            assignRights: []
        };

        hasUserManage && model.assignRights.push("user-management");
        hasGroupManage && model.assignRights.push("group-management");
        hasCategoryManage && model.assignRights.push("category-management");
        qa && model.assignRights.push("tester");
        vocab && model.assignRights.push("vocabulary");

        const request = axios.post(`${app.apiBasePath}/admin/users/add`, model);
        request.then(result => {
            if (result.status === StatusCodes.OK) {
                setIsUpdating(false);
                nav("/admin/users");
            }

        }).catch(error => {
            setIsUpdating(false);
            const emailMatch = error.response?.data === "email-match";

            if (emailMatch) {
                setValidators(vals => {

                    const v = vals.map(p => {
                        if (p.type === "email") {
                            p.valid = false;
                            p.msg = localization["emailActiveAlready"]
                        }
                        return p;
                    });
                    return [...v];
                })
            }

        })
    };

    return (
        <div className={classNames(style.module, "form", style.usersform)}>
            <h2 className="m">{localization["newUserTitle"]}</h2>
            <div className={classNames("row", validators.find(p => p.type === "email")?.valid === false ? style.warn : null)}>
                <label>{localization["EmailAddress"]}*</label>
                <input type="text"
                    autoComplete="chrome-off"
                    disabled={isUpdating}
                    onChange={(e) => { setEmail(e.target.value); runValidator(e.target.value, "email"); }}
                    value={email}
                    className="form-control" />
                {
                    !IsNullOrWhiteSpace(validators.find(p => p.type === "email").msg) &&
                    <p className={style.erroMsg}>{validators.find(p => p.type === "email").msg}</p>
                }

            </div>

            <div className={classNames("row", validators.find(p => p.type === "firstName")?.valid === false ? style.warn : null)}>
                <label>{localization["FirstName"]}*</label>
                <input type="text"
                    autoComplete="chrome-off"
                    disabled={isUpdating}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        runValidator(e.target.value, "firstName");
                    }}
                    value={firstName}
                    className="form-control" />
            </div>

            <div className="row extra-space">
                <label>{localization["LastName"]}</label>
                <input type="text"
                    autoComplete="chrome-off"
                    disabled={isUpdating}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    value={lastName}
                    className="form-control" />
            </div>

            <h4>Accès administratif</h4>
            <div className={classNames("row", style.checkboxes)}>
                <div><Checkbox checked={hasUserManage} label="Gestion JEUeurs" onCheckChange={() => { setHasUserManage(!hasUserManage) }} /></div>
                <div><Checkbox checked={hasGroupManage} label="Gestion groupes" onCheckChange={() => { setHasGroupManage(!hasGroupManage) }} /></div>
                <div><Checkbox checked={hasCategoryManage} label="Gestion categories" onCheckChange={() => { setHasCategoryManage(!hasCategoryManage) }} /></div>
                <div><Checkbox checked={vocab} label="Gestion vocabulaire" onCheckChange={() => { setVocab(!vocab) }} /></div>
                <div><Checkbox checked={qa} label="Assurence Qualité" onCheckChange={() => { setQA(!qa) }} /></div>
            </div>

            <div className="row">
                <h4>Selectionner une our plusieurs groupes</h4>

                <Select
                    menuPortalTarget={document.body}
                    placeholder={"sélectionner"}
                    value={selectedGroups}
                    onChange={onGroupSelectionChange}
                    isMulti={true}
                    options={groups} />
            </div>

            <div className="row">
                <label>{localization["PasswordTemp"]}</label>
                <div className={style.inpbutton}>
                    <input ref={pwdRef} type="text" onClick={onPwdFiledClick} className="form-control" value={password} readOnly={true} />
                    <button onClick={generateNewPassword}>
                        <FontAwesomeIcon icon="refresh" />
                    </button>
                </div>
            </div>

            <div className="row buttons">
                <button
                    className="btn cancel"
                    disabled={isUpdating}
                    onClick={() => { nav("/admin/users"); }}>{localization["cancel"]}</button>
                <button
                    onClick={submitUserCreate}
                    className="btn action"
                    disabled={validators.some(p => p.valid === false)}>{localization["submit"]}</button>
            </div>
        </div>

    )
};