export enum Message {
    UpdateInboxExchanges = 203,
    DeleteDraft,
    UnpublishExchange,
    UpdateExchangeStatePublished,
    AddFavoriteExchange,
    RemoveFavoriteExchange,
    AddAttachmentToExchange,
    RemoveAttachmentFromExchange,
    RefreshCategories,
    RefreshVocabulary,
    SetProfileDebugQueyText,
    SaveProfileDebugResult,
    NextPage,

    SocketCommand,
    SocketNotification,
    SocketEvent
}