import { create } from "zustand";
import { Person } from "../models/Person";
import { Dictionary } from "../interfaces/Dictionary";
import { Group } from "../models/Group";
import { Category } from "../models/Category";
import { GroupInfo } from "../models/JoinedGroup";
import { GroupPath } from "../models/GroupPath";

export type AppStore = {
    person: Person | null;
    setPerson: (person: Person) => void;

    localization: Dictionary<string>;
    setLocalization: (localization: Dictionary<string>) => void;

    groups: Array<Group>;
    setGroups: (groups: Array<Group>) => void;

    categories: Array<Category>;
    setCategories: (categories: Array<Category>) => void;

    localGroups: Array<GroupInfo>;
    setLocalGroups: (localGroups: Array<GroupInfo>) => void;

    groupPath: Array<GroupPath>;
    setGroupPath: (groupPath: Array<GroupPath>) => void;
}

export const appStore = create<AppStore>((set) => ({
    person: null,
    setPerson: (person) => set({ person }),
    localization: {},
    setLocalization: (localization) => set({ localization }),
    groups: [],
    setGroups: (groups) => set({ groups }),
    categories: [],
    setCategories: (categories) => set({ categories }),
    localGroups: [],
    setLocalGroups: (localGroups) => set({ localGroups }),
    groupPath: [],
    setGroupPath: (groupPath) => set({ groupPath })
}));
