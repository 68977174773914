import { Handler, MessageArg } from './interfaces/CoreInterfaces';



export interface RemoteEvent {
    id: number;
    subId?: number;
    data?: any;
}

export interface EventRouterOptions{
    onBeforeCall?: Function | undefined;
    onAfterCall?: Function | undefined;
    isDebug?: boolean | undefined;
}
export class EventRouter<T> {

 
    private handlers: Array<Handler>;
    private onBeforeCall?: Function | null;
    private onAfterCall?: Function | null;
    private isDebug : boolean;

    private defaultPromise = new Promise<boolean>(x=> false);

    constructor(opt?: EventRouterOptions) {

        this.handlers = [];
        if(typeof opt !== "undefined" && opt !== null){
            this.onBeforeCall = opt.onBeforeCall;
            this.onAfterCall = opt.onAfterCall;
            this.isDebug = opt.isDebug ?? false;
        }
        else{
            this.isDebug = false;
        }
       
    }


    public addHandler(handler: Handler) : void {
        if (typeof handler !== 'undefined' && handler) {
            this.handlers.push(handler);            
        }
    }

     
    public route(data: MessageArg, state: T): boolean | Promise<boolean> {
        let self = this;
        let methodFound = false;

        let result;

        if (this.isDebug) {console.log("local event", data);}

        this.handlers.forEach((handler: Handler) => {

            Object.getOwnPropertyNames(handler).forEach((m: string) => {

                let fn = handler[m as keyof Handler] as Function;

                let op = fn["message"] as number | number[];
                
                if (typeof op !== "undefined" && op !== null) {
                    
                    if(op === data.id || (typeof op === "object" && op.length && op.some(x=>{return x === data.id;}))) {
                        methodFound = true;
                        if (this.isDebug) {
                            console.log("invoking:", handler.constructor["name"] + '.' + fn["name"], 'message: ' + data.id);
                        }
                        let call = true;
                        if (typeof self.onBeforeCall === "function") {
                            call = self.onBeforeCall(handler, data, state);
                        }
                        if (call) {
                            result = fn.apply(handler, [data, state]);

                        }
                        if (typeof self.onAfterCall  === "function") {
                            self.onAfterCall(handler, data, state);
                        }       
                    }
                }
            });
        });

        if (this.isDebug && !methodFound) {
            console.log("no handler function registered for", data.id);
        }

        return result;
    }
}