import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { Person } from '../../models/Person';
import { Image } from '../../components/Image';
import { IsNullOrWhiteSpace, StringIsNullOrEmpty } from '../../misc/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import unknownImage from '../../images/unknown.png';
import style from './profile.module.scss';
import classNames from 'classnames';



const ProfileView = (): JSX.Element => {

    const { person, groups } = useContext(AppContext);
    let { localization } = useContext(LocalizationContext);

    const emptyOr = (str: string, replacement: string): string => {
        return IsNullOrWhiteSpace(str) ? replacement : str;
    }

    const hasLocationSet = person.locationLatitude !== 0 && person.locationLatitude !== 0;

    return (
        <div className="page-container">
            <div className={style.scroll}>
                <div className={classNames("form", style.profileform)}>
                    <div className="row">
                        {
                            !StringIsNullOrEmpty(person.avatarFileId) &&
                            <Image
                                fileId={person.avatarFileId}
                                altText={person.displayName} />
                        }
                        {
                            StringIsNullOrEmpty(person.avatarFileId) &&
                            <img width={100} src={unknownImage} alt="unknown" />
                        }
                    </div>
                    <div className="row">
                        <label>{localization["DisplayName"]}</label>
                        <span>{emptyOr(person.displayName, localization["emptyEntry"])}</span>
                    </div>
                    <div className="row">
                        <label>{localization["FirstName"]}</label>
                        <span>{emptyOr(person.firstName, localization["emptyEntry"])}</span>
                    </div>
                    <div className="row">
                        <label>{localization["LastName"]}</label>
                        <span>{emptyOr(person.lastName, localization["emptyEntry"])}</span>
                    </div>
                    <div className="row">
                        <label>{localization["PrimaryNumber"]}</label>
                        <span>{emptyOr(person.primaryNumber, localization["emptyEntry"])}</span>
                    </div>
                    <div className="row">
                        <label>{localization["SecondaryNumber"]}</label>
                        <span>{emptyOr(person.secondaryNumber, localization["emptyEntry"])}</span>
                    </div>
                    <div className="row">
                        <label>{localization["EmailAddress"]}</label>
                        <span>{person.email}</span>
                    </div>

                    <div className="row">
                    <label>{localization["menuGroupsProfile"]}</label>
                        {
                            groups.map(g=>{
                                return <div key={g.id}><span>{g.name}</span></div>
                            })
                        }
                    </div>

                    <div className="row">
                        <label>{localization["Location"]}</label>
                        {
                            hasLocationSet &&
                            <div className={style.geo}>
                                <FontAwesomeIcon className={style.available} icon="location-dot" />
                                {
                                    !StringIsNullOrEmpty(person.locationLabel) &&
                                    <span style={{ cursor: "help" }} title={`${person.locationLatitude} ${person.locationLongitude}`}>{person.locationLabel}</span>
                                }
                                {
                                    StringIsNullOrEmpty(person.locationLabel) &&
                                    <>
                                        <span>{person.locationLongitude}</span>
                                        <span>{person.locationLatitude}</span>
                                    </>
                                }

                            </div>
                        }
                        {
                            !hasLocationSet &&
                            <div className={style.geo}>
                                <FontAwesomeIcon className={style.available} icon="location-dot" />
                                <p>{localization["locationNotSelected"]}</p>
                            </div>
                        }

                    </div>



                    <div className="row">
                        <Link className="btn action" to="/profile/edit">
                            {localization["EditProfile"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    );
}

export { ProfileView }