import { Locale } from "./Locale";

export enum VocabularyScope {
    UserInterface = 0,
    Category
}

export type Vocabulary = {
    id: string;
    key : string;
    value : string;
    culture: Locale;
    scope?: VocabularyScope;
}