import classNames from 'classnames';
import { ReactElement, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { app } from '../..';
import { LocalizationContext } from '../../interfaces/AppContext';
import { PubSubTopic } from '../../misc/Constants';
import { Message } from '../../misc/EventMessages';
import { IsNullOrWhiteSpace } from '../../misc/Utilities';
import { Person } from '../../models/Person';
import noImg from "../../images/unknown.png";
import { Locale } from '../../models/Locale';
import style from './dropdown.module.scss';

export interface ProfileDropdownProps {
    person: Person;
    closeDropdown: () => void;
}
export const ProfileDropdown = (props: ProfileDropdownProps): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const { person, closeDropdown } = props;

    const setLocale = (locale: Locale): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RefreshVocabulary,
            data: {
                locale: locale
            }
        });
    };

    useEffect(() => {
        function requestClose() : void {
            closeDropdown();
        }
        window.addEventListener("click", requestClose);
        return () => {
            window.removeEventListener("click", requestClose);
        };
    }, []);

    return (
        <div onClick={(e) => { e.stopPropagation() }} className={style.dropdown}>
            <div className={style.s}>
                <div className="flex">
                    <div className={style.side1}>
                        {
                            IsNullOrWhiteSpace(person.avatarFileId) &&
                            <img width={60} src={noImg} alt="unknown" />
                        }
                        {
                            !IsNullOrWhiteSpace(person.avatarFileId) &&
                            <img className={style.avatar} src={app.apiBasePath + "/assets/image/" + person.avatarFileId} alt={person.displayName} />
                        }

                    </div>
                    <div className={style.side2}>
                        <div className={style.s1}>
                            <div>
                                <span>{person.displayName}</span>
                            </div>
                        </div>
                        <div className={classNames(style.s1, style.links)}>
                            <Link onClick={closeDropdown} to="/profile">{localization["view"]}</Link>
                            <Link onClick={closeDropdown} to="/profile/edit">{localization["edit"]}</Link>

                            {
                                false &&
                                <a onClick={() => setLocale("en")}>EN</a>
                            }
                        </div>
                        <div style={{ marginTop: "20px", textAlign: 'right' }}>
                            <a className="right" href={app.apiBasePath + "/auth/signout"}>{localization["signout"]}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};