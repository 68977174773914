import { ReactElement, useContext, useEffect, useState } from "react";



import style from "./categorymanagement.module.scss";
import axios from "axios";
import { app } from "../../..";
import { Category } from "../../../models/Category";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { IsUndefinedOrNull } from "../../../misc/Utilities";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CategoryManagement = (): ReactElement => {

    const [categories, setCategories] = useState<Category[]>([]);

    const { localization } = useContext(LocalizationContext);

    const nav = useNavigate();

    useEffect(() => {

        axios.get(`${app.apiBasePath}/assets/categories`)
            .then(response => {
                setCategories(response.data);
            });


    }, []);

    return (
        <div className={style.container}>
            <div className="form">
                <h1>{localization["categoryManagement"]}</h1>
                <div className={style.subheading}>
                    <Link className="btn action" to="add">{localization["newCategory"]}</Link>
                </div>
                <div className={style.list}>
                    {
                        categories.filter(p => IsUndefinedOrNull(p.parentId)).map(c => {
                            return (
                                <div className={style.category} key={c.id}>
                                    <Link to={`/admin/categories/${c.id}`}>
                                        <h3>{localization[c.labelKey]}</h3>
                                        <div className={style.description}>
                                            <span>{localization[c.descriptionKey]}</span>
                                        </div>
                                        <div className={style.example}>
                                            <span>{localization[c.exampleKey]}</span>
                                        </div>
                                    </Link>
                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>


        </div>
    );
}