import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Link } from "react-router-dom";
import { NavItem } from "../interfaces/NavItem";
import { IsNullOrWhiteSpace, IsUndefinedOrNull, isEmptyCollection } from "../misc/Utilities";
import style from "../styles/mobilemenu.module.scss";
import React from "react";
import logo from "../images/jardinweb_logo_180x22.svg";


export interface MobileMenuProps {
    visible: boolean;
    close: () => void;
    links: NavItem[];
}
export const MobileMenu = (props: MobileMenuProps): JSX.Element => {

    return (
        <div className={props.visible ? classNames(style.container, style.show) : style.container}>
            <div className={style.heading}>

                <img src={logo} width={150} alt="JardinWeb" />


                <button onClick={props.close}>
                    <FontAwesomeIcon icon="x" />
                </button>
            </div>


            <div className={style.menu}>

                {
                    props.links.filter(x=> !IsNullOrWhiteSpace(x.path) || !isEmptyCollection(x.items)).map((link, idx: number) => {
                        return (
                            <div className={style.primary} key={idx}>
                                {
                                    !IsNullOrWhiteSpace(link.path) &&
                                    link.items === null &&
                                    <div className={style.toplink}>
                                        {
                                            !IsUndefinedOrNull(link.fontAwesomeName) &&
                                            <FontAwesomeIcon icon={link.fontAwesomeName} />
                                        }
                                        <Link onClick={props.close} to={link.path}>{link.label}</Link>
                                    </div>
                                }
                                {
                                    IsNullOrWhiteSpace(link.path) &&
                                    <span>{link.label}</span>
                                }
                                {
                                    !IsUndefinedOrNull(link.items)
                                    && link.items.length > 0 &&
                                    <div className={style.subone}>
                                        {
                                            link.items.map((sublink, idx2: number) => {
                                                const link = sublink as NavItem;
                                                return (
                                                    <Link onClick={props.close} key={"dsddad" + idx2} to={link.path}>
                                                        {
                                                            !IsUndefinedOrNull(link.fontAwesomeName) &&
                                                            <FontAwesomeIcon icon={link.fontAwesomeName} />
                                                        }
                                                        {link.label}</Link>
                                                );
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }

            </div>


        </div>);
};