
import './styles/app.global.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { MainStore } from './store/MainStore';
import axios, * as Axios from 'axios';
import { ContextResult, GetCategories, GetConfiguration, GetContext, GetLocalization, GetMyExchanges, GetMyFavoriteExchanges } from './misc/Requests';
import { library } from '@fortawesome/fontawesome-svg-core';

import 'moment-locale-fr';

import {
    faLocationDot,
    faUser,
    faAngleDown,
    faCheck,
    faPen,
    faTrashCan,
    faPlus,
    faX,
    faCircleInfo,
    faChevronRight,
    faChevronLeft,
    faFlaskVial,
    faTrash,
    faImage,
    faUpload,
    faArrowRightArrowLeft,
    faBars,
    faLocationCrosshairs,
    faHouse,
    faUserGroup,
    faUserPlus,
    faExclamationCircle,
    faFloppyDisk,
    faPause,
    faCirclePause,
    faEllipsis,
    faFilter,
    faEye,
    faSearch,
    faCaretSquareUp,
    faCaretSquareDown,
    faRefresh,
    faBell,
    faStar,
    faCamera, faListCheck, faCopy, faHandPointUp, faFileLines, faCode, faPersonCircleExclamation, faHandHoldingHeart, faLock, faEnvelope, faCaretDown, faChevronDown, faArrowDownWideShort, faGear
} from '@fortawesome/free-solid-svg-icons';

import { LocalizationContext } from './interfaces/AppContext';
import { GateApp } from './pages/GateApp';
import { StatusCodes } from 'http-status-codes';
import { PubSubTopic } from './misc/Constants';
import { loadSearchDateFromLocalStorage, StorageItems } from './services/LocalStorageService';
import { AgreementApp } from './pages/AgreementApp';
import { appStore } from './store/appStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { appKeys } from './queryKeys/appKeys';

export { app };

/*
moment.locale('en');
moment.locale('fr-ca');
*/


class Bootstrap {

    store = new MainStore();

    gglApiKey = "";

    captchaSiteKey = "";

    apiBasePath = "";

    wsUrl = "";

    build = "";

    db: IDBDatabase = null;

    initDb(): void {

        const dbVersionChangeHandler = (event: any) => {
            console.log(event, "version change required");
        };
        const dbCloseHandler = (event: any) => {
            this.db = null;
        };

        const request = indexedDB.open("test", 1);

        request.onerror = (error: Event): void => {
            console.warn(error, "indexedDB error");
        };

        request.onupgradeneeded = (event: any): void => {
            const database = event.target.result as IDBDatabase;

            // see examples here for init
            // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB



        };

        request.onsuccess = (event: any): void => {
            this.db = event.target.result as IDBDatabase;
            this.db.onerror = error => PubSub.publish(PubSubTopic.DBErrors, error);
            this.db.onabort = abort => PubSub.publish(PubSubTopic.DBEvents, abort);
            this.db.onclose = dbCloseHandler;
            this.db.onversionchange = dbVersionChangeHandler;
        };
    }

    start(): void {

        //this.initDb();

        library.add(
            faRefresh,
            faLocationDot,
            faUser,
            faAngleDown,
            faCheck,
            faPen,
            faTrashCan,
            faPlus,
            faX,
            faCircleInfo,
            faChevronRight,
            faChevronLeft,
            faFlaskVial,
            faTrash,
            faImage,
            faUpload,
            faArrowRightArrowLeft,
            faHandHoldingHeart,
            faBars,
            faLocationCrosshairs,
            faHouse,
            faUserGroup,
            faUserPlus,
            faExclamationCircle,
            faFloppyDisk,
            faCirclePause,
            faEllipsis,
            faFilter,
            faEye,
            faSearch,
            faPause,
            faStar,
            faHandPointUp,
            faCaretSquareUp,
            faLock,
            faChevronDown,
            faGear,
            faArrowDownWideShort,
            faCaretSquareDown, faBell, faCamera, faListCheck, faFilter, faCopy, faFileLines, faCircleInfo, faListCheck, faCode, faPersonCircleExclamation, faEnvelope);

        axios.defaults.withCredentials = true;


        const configRequest = GetConfiguration();

        axios.interceptors.response.use((response) => { return response; }, (err: Axios.AxiosError) => {
            if (err.response?.status === StatusCodes.UNAUTHORIZED) {

                const a = /resetpwd/.test(window.location.pathname);
                const b = /resetwithcode/.test(window.location.pathname);
                const c = /bienvenue/.test(window.location.pathname);
                const d = /confirmed/.test(window.location.pathname);
                const e = /failure/.test(window.location.pathname);
                if (a || b || c || d || e) {
                    return Promise.resolve();
                }
                else if (window.location.pathname !== "/") {
                    window.location.href = "/";
                }
            }
            else if (err.message === "Network Error") {
                return Promise.reject("network");
            }
            else return Promise.reject();
        });

        const appContainer = ReactDOM.createRoot(document.getElementById('root'));

        configRequest.then(config => {
            const queryClient = new QueryClient({
                defaultOptions: {
                    queries: {
                        gcTime: 1000 * 60 * 5
                    }
                }
            });

            this.captchaSiteKey = config.captchaSiteKey;
            this.gglApiKey = config.gglApiKey;
            this.apiBasePath = config.apiPath;
            this.build = config.build;
            this.wsUrl = config.wsUrl;

            const localizationRequst = GetLocalization("fr");

            localizationRequst.catch(e => {
                appContainer.render(<p>API not available</p>);
            });

            localizationRequst.then(localization => {

                const contextRequest = GetContext("groupInfo", "personInfo", "terms", "token");

                contextRequest.then((result: ContextResult) => {
                    if (!result.success) {
                        const path = window.location.pathname;
                        if (localStorage) {
                            localStorage.setItem("redirect-to", path);
                        }

                        if (result.error === "login") {
                            appContainer.render(
                                <React.Fragment>
                                    <LocalizationContext.Provider value={{ localization: localization }}>
                                        <GateApp />
                                    </LocalizationContext.Provider>
                                </React.Fragment>
                            );
                        }
                        else if (/Network Error/.test(result.error)) {
                            appContainer.render(<p>API not available</p>);
                        }
                    }
                    else {
                        const categoriesRequest = GetCategories();
                        const exchangesRequest = GetMyExchanges();

                        const allRequests = Promise.all([categoriesRequest, exchangesRequest]);


                        allRequests.then(([categories, exchanges]) => {

                            const { setPerson, setGroupPath, setGroups, setCategories, setLocalGroups, setLocalization } = appStore.getState();
                            setLocalization(localization);
                            setPerson(result.context.person);

                            // init local storages
                            const key: StorageItems = "__search_results";
                            localStorage.removeItem(`${result.context.person.personId}.${key}`);
                            localStorage.removeItem(`${result.context.person.personId}.collapseSearch`);

                            setGroups(result.context.groups);
                            setGroupPath(result.context.groupPath);
                            setCategories(categories);
                            setLocalGroups(result.context.localGroups);

                            app.store.state.exchanges = exchanges;

                            loadSearchDateFromLocalStorage(result.context.person.personId);

                            window["app"] = app;

                            if (!result.context.agreementAccepted) {
                                appContainer.render(
                                    <AgreementApp localization={localization} />
                                );

                                return;
                            }

                            appContainer.render(
                                <QueryClientProvider client={queryClient}>
                                    <App socketsToken={result.context.integrationToken} />
                                </QueryClientProvider>
                            );
                        });
                    }
                });
            });
        });
    }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

var app = new Bootstrap();
app.start();
reportWebVitals();