import classNames from 'classnames';
import * as React from 'react';
import style from '../styles/emptyframe.module.scss';

export interface IEmptyFrame{
    onFrameClick?: ()=> void;
    title?: string;
    warn?: boolean;
}
const EmptyFrame = (props) : JSX.Element =>{

    let css = style["empty-frame"];
    if(props.warn === true){
        css =classNames(style["empty-frame"], style.warning);
    }

    return (
        <div title={props.title} onClick={props.onFrameClick?.bind(this)} className={css}>             
            <div className={style.ps + " ico-add-24"}></div>
            <div className="ico-photo-32"></div>
        </div>
            );
};

export {EmptyFrame}