 
import { HandleType, LocalEvent } from '../core/interfaces/CoreInterfaces';
 

export class LoginEventsHandler  {  
 
    constructor(){
        this.TestFunction.message = [101,102];
        
    }

    TestFunction = <HandleType>((arg: LocalEvent, state: object): boolean => {
       
 

        return true;
    });
 
}

