import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Marker } from "@react-google-maps/api";
import React from "react";

import { ReactElement, useEffect, useRef, useState } from "react";
import { useDeepCompareEffectForMaps } from "../misc/MapHooks";
import { MapMarker } from "../models/MapMarker";




export const GGLMarker = (options: google.maps.MarkerOptions): ReactElement => {

    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };

    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
}

export interface GGLMapProps {
    children?: any;
    options: google.maps.MapOptions;
    style?: { [key: string]: string };

    onClick?: (point: google.maps.LatLng, zoom: number) => void;


    onIdle?: (map: google.maps.Map) => void;
}

export const GGLMap = (props: GGLMapProps): ReactElement => {

    const render = (status: Status) => {
        return <h1>{status}</h1>;
    };

    const defOpt: google.maps.MapOptions = {

        center: {
            lng: -71.4,
            lat: 45.9
        },
        minZoom: 3,
        maxZoom: 100,
        zoom: 7
       
    };

    const opt = { defOpt, ...props.options };

    const [map, setMap] = useState<google.maps.Map>();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }

    }, [ref, map]);

    useDeepCompareEffectForMaps(() => {
        if (map) {

            map.setOptions(opt);

            if (typeof props.onIdle === "function") {
                map.addListener("idle", props.onIdle);
            }

            if (typeof props.onClick === "function") {

                map.addListener("click", (sender) => {
                    
                    props.onClick(sender.latLng, map.getZoom());
                })
            }

            map.addListener("bounds_changed ", (b)=>{
               // console.log(b, "bounds_changed");
            });

            map.addListener("zoom_changed ", (b)=>{
               // console.log(b, "bounds_changed");
            });

        }

    }, [map, props.options]);



    return (
        <>
            <div style={props.style} ref={ref}></div>
            {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    )
}
