import React from 'react';
import { app } from '..';
import style from '../styles/image.module.scss';

export interface ImageProps{
    fileId: string;
    onDelete? : (fileId: string) => void;
    onExpand? : () => void;
    altText? : string;
}

const Image = (props: ImageProps) : JSX.Element  =>{
    return <div className={style.image}>
        {
            typeof props.onDelete === "function" &&
            <div onClick={props.onDelete.bind(this, props.fileId)} className={style["delete-button"]}></div>
        }
        <img className="no-select" src={app.apiBasePath + '/assets/image/' + props.fileId}  alt={props.altText} />
    </div>
};
export {Image }