import { FC, MouseEvent, ReactElement, useContext } from "react";
import { LocalizationContext } from "../../interfaces/AppContext";
import { FavoriteExchange } from "../../models/FavoriteExchange";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { app } from "../..";
import noimg from "../../images/no-image.jpg";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";
import style from "./favorites.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeFavorite } from "../../requests/favoriteRequests";
import { appKeys } from "../../queryKeys/appKeys";

export interface FavoritesPageProps {
    items: FavoriteExchange[];
}

export const FavoritesPage: FC<FavoritesPageProps> = ({ items }): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const queryClient = useQueryClient();

    const favoritesUpdater = useMutation({
        mutationFn: async (id: string) => removeFavorite(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: appKeys.favorites
            });
        }
    });

    const nav = useNavigate();

    const onRemoveFavorite = (event: MouseEvent, item: FavoriteExchange): void => {
        if (favoritesUpdater.isPending) {
            return undefined;
        }

        event.stopPropagation();
        favoritesUpdater.mutate(item.exchangeId);
    };

    const navigateToItem = (event: MouseEvent, fav: FavoriteExchange): void => {
        nav(`/exchanges/${fav.exchangeId}`);
    };

    return (
        <div className={style.container}>
            <div className="form">
                <h1>{localization["myFavotires"]}</h1>

                <div className={style.list}>
                    {
                        items.map(fav => {
                            return (


                                <div className={style.fav} onClick={e => navigateToItem(e, fav)} key={fav.exchangeId}>
                                    <div className={style.f}>
                                        <div className={style.h}>
                                            <h3>{fav.exchangeTitle}</h3>
                                            <div>
                                                <FontAwesomeIcon icon="x" onClick={x => onRemoveFavorite(x, fav)} />
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                !IsNullOrWhiteSpace(fav.fileId) &&
                                                <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${fav.fileId}`} alt="image non trouvé" />
                                            }
                                            {
                                                IsNullOrWhiteSpace(fav.fileId) &&
                                                <img src={noimg} />
                                            }
                                        </div>
                                    </div>

                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};