import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useContext, useState } from "react"
import { IsUndefinedOrNull } from "../misc/Utilities";
import ReactLoading from "react-loading";
import style from "../styles/welcome.module.scss";
import classNames from "classnames";
import axios from "axios";
import { app } from "..";
import { StatusCodes } from "http-status-codes";
import { LocalizationContext } from "../interfaces/AppContext";
import { NotificationView, NotificationType } from "../components/NotificationView";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export type CodeCheckResult = {
    email: string;
    firstName: string;
    lastName: string;
    groupName: string;
    existingAccount: boolean;
    pass: boolean;
    errorCode: string;
};

export const WelcomePage = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const [code, setCode] = useState("");
    const [busy, setBusy] = useState(false);
    const [validationFailed, setValidationFailed] = useState(false);
    const [warning, setWarning] = useState("");

    const nav = useNavigate();


    const sanitizeCode = (code: string): string => {
        if (IsUndefinedOrNull(code)) return "";
        return code.replace(/\s/g, '').toUpperCase();
    }

    const reset = (): void => {
        setCode("");
        setValidationFailed(false);
    };

    const onCodeChange = (code: string): void => {
        setValidationFailed(false);
        setCode(code);
    };

    const c = sanitizeCode(code);

    const verifyCode = async (): Promise<void> => {
        if (busy || validationFailed) return;
        if (c.length < 6) return;

        setBusy(true);

        try {

            const result = await axios.post(`${app.apiBasePath}/public/i/codecheck`, {
                code: code
            });

            if (result.status === StatusCodes.BAD_REQUEST) {
                setBusy(false);
            }

            setBusy(false);

            const model = result.data as CodeCheckResult;

            if (!model.pass) {
                if (model.errorCode === "duplicate") {
                    setWarning("duplicateAccount");
                }
                else {
                    setWarning("invalidCode");
                }

                setValidationFailed(true);
            }
            else {
                nav(code);
            }
        }
        catch (e: any) {
            setBusy(false);
            //const error = e as AxiosError;
            //console.log(error.message);
        }
    };

    return (
        <div className={style.container}>
            <h1>Bienvenue au JardinWeb!</h1>
            <p>Pour compléter ton inscription, entre le code d'invitation que tu as reçu lors d'une rencontre JEU de ton groupe local.</p>
            <p className={style.sm}>Si ton groupe local n'a pas encore son accès JardinWeb, tu peux informer les facilitateurs de l'existence du portail.
                Ces derniers peuvent nous contacter à l'adresse <a href="mailto:admin@jardinweb.org">admin@jardinweb.org</a></p>

            <hr />
            <div className={classNames(style.row, style.code)}>


                <div className={classNames(style.codeInput, validationFailed ? style.fail : null)}>



                    <input
                        disabled={busy}
                        value={c}
                        onChange={e => onCodeChange(e.target.value)}
                        type="text"
                        placeholder="Inscris ton code d'invitation"
                        onBlur={verifyCode} />
                    {
                        c.length > 5 &&
                        !busy &&
                        !validationFailed &&
                        <FontAwesomeIcon icon="chevron-right" />
                    }
                    {
                        busy &&
                        <ReactLoading type={"spin"} color={"#5270F0"} height={28} width={28} />
                    }
                    {
                        !busy &&
                        validationFailed &&
                        <FontAwesomeIcon onClick={reset} icon="x" />
                    }
                </div>

                {
                    validationFailed &&
                    <div className={style.notice}>
                        <NotificationView type={NotificationType.Warning}>
                            <p>{localization[warning]}</p>
                        </NotificationView>
                    </div>
                }


            </div>
            <div className={style.return}>
                <Link to="/"> <FontAwesomeIcon icon="chevron-left" /> retour</Link>
            </div>



        </div>
    )
}