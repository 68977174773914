import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import classNames from "classnames";
import { ReactElement, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { app } from "../..";
import { InputWarning } from "../../components/InputWarning";
import { ErrorArg, useForm, ValidationMethods } from "../../hooks/UseForm";
import { LocalizationContext } from "../../interfaces/AppContext";
import { EmailValidationPattern } from "../../misc/Constants";
import style from "./invitation-form.module.scss";
import { KeyValuePair } from "../../models/KeyValuePair";
import { StatusCodes } from "http-status-codes";
import { StringIsNullOrEmpty } from "../../misc/Utilities";

interface FormModel {
    email: string;
    firstName: string;
    lastName: string;


    existingAccount: boolean;
}

enum InvitationFormValidators {
    email,
    firstName,
    lastName
}

interface InviteMemberResult extends ExistingUserInfo {
    success: boolean;
    errorCode: string;
    shortCode: string;
}

type ExistingUserInfo = {
    groupNames?: KeyValuePair[];
    firstName?: string;
    lastName?: string;
    personId?: string;
}

type AccountInfo = {
    firstName: string;
    lastName: string;
    email: string;
    personId: string;
};

const Confirmation = ({ code, email }: { code: string, email: string }): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    return (
        <div className={style.container}>
            <div className={style.success}>
                <h2><FontAwesomeIcon icon={"check"} />{localization["invitationCreated"]}</h2>
                <h3>{email}</h3>
                <p>Code: <span>{code}</span></p>
            </div>
        </div>
    )
};


export const InvitationForm = ({ groupId, groupName }: { groupId: string, groupName: string }): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const vocab = localization;

    const [emailCheckPass, setEmailCheckPass] = useState(null as boolean);
    const [duplicateInvitation, setDuplicateInvitation] = useState(null as boolean);

    const [code, setCode] = useState("");
    const [sending, setSending] = useState(false);

    const [userOtherGroups, setUserOtherGroups] = useState<ExistingUserInfo>(null);

    const nav = useNavigate();

    const initModel: FormModel = {
        email: "",
        firstName: "",
        lastName: "",
        existingAccount: false
    };

    const formValidators: ValidationMethods<FormModel>[] = [
        {
            validator: InvitationFormValidators.email,
            use: (model: FormModel): ErrorArg => {
                return EmailValidationPattern.test(model.email) ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: "email" };
            }
        },
        {
            validator: InvitationFormValidators.firstName,
            use: (model: FormModel): ErrorArg => {

                return model.firstName.trim().length === 0 ?
                    { isValid: false, message: "first-name" }
                    : { isValid: true, message: "" };
            }
        },
        {
            validator: InvitationFormValidators.lastName,
            use: (model: FormModel): ErrorArg => {

                return model.lastName.trim().length === 0 ?

                    { isValid: false, message: "last-name" } : { isValid: true, message: "" };
            }
        }
    ];

    const { values, isValid, valueSetter, error } =
        useForm<FormModel, InvitationFormValidators>(initModel, formValidators);

    const checkAccountExists = (): void => {

        if (!StringIsNullOrEmpty(values.email?.trim())) {
            const request = axios.get(`${app.apiBasePath}/search/account?email=${values.email?.trim()}`);

            request.then(r => {
                if (r.status === StatusCodes.OK) {
                    const acc = r.data as AccountInfo;
                    valueSetter([
                        {
                            name: "lastName",
                            value: acc.lastName
                        },
                        {
                            name: "firstName",
                            value: acc.firstName
                        },
                        {
                            name: "existingAccount",
                            value: true
                        }
                    ]);
                }
            });
        }
    };

    const inviteUser = (): void => {

        setSending(true);

        const dto = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            groupId: groupId
        };

        const request = axios.post(`${app.apiBasePath}/groupadmin/${groupId}/invitememeber`, dto);

        request.then(result => {

            setSending(false);

            const r = result.data as InviteMemberResult;

            if (r.errorCode === "user-exist") {
                setEmailCheckPass(false);
            }
            else if (r.errorCode === "invite-exist") {
                setDuplicateInvitation(false);
            }
            else if (r.errorCode === "user-has-account") {
                setUserOtherGroups({ ...r });
            }
            else if (r.errorCode === "") {
                setCode(r.shortCode);
            }
        });
    };

    if (code.length > 0) {
        return <Confirmation code={code} email={values.email} />
    }

    const completeInvitation = (): void => {

        if (userOtherGroups === null)
            return;

        setSending(true);

        const dto = {
            personId: userOtherGroups.personId,
            groupId: groupId
        };

        const request = axios.post(`${app.apiBasePath}/groupadmin/${groupId}/makejoin`, dto);

        request.then(result => {
            if (result.status === StatusCodes.OK) {
                nav(`/group/manage/${groupId}/users`);
            }
        });
    };


    return (

        <div className={style.container}>
            <h3>{localization["makeInvitation"]}</h3>
            {
                userOtherGroups === null &&
                <div className="form invitation">

                    <div className="row">
                        <label>{vocab["EmailAddress"]}</label>
                        <input
                            value={values.email}
                            onBlur={checkAccountExists}
                            onChange={e => {
                                //setEmailCheckPass(null);
                                valueSetter([{ name: "email", value: e.target.value }, { name: "existingAccount", value: false}])
                            }}
                            className={classNames(
                                "form-control",
                                error(InvitationFormValidators.email).isValid ? null : "validation-warning",
                                emailCheckPass === false ? "validation-warning" : null,
                                duplicateInvitation === false ? "validation-warning" : null)
                            }
                            type="text" />

                        {
                            emailCheckPass === false &&
                            <InputWarning messageKey="emailActiveAlready" />
                        }
                        {
                            duplicateInvitation === false &&
                            <InputWarning messageKey="userAlreadyInvited" />
                        }
                    </div>

                    {
                        values.existingAccount &&
                        <div className={style.match}>
                            <p>Compte existe déjà.</p>
                            <button onClick={inviteUser} className="btn action">Inviter</button>
                            <span>{values.firstName} {values.lastName} à joindre {groupName}</span>
                        </div>
                    }

                    {
                        !values.existingAccount &&
                        <>
                            <div className="row">
                                <label>{vocab["FirstName"]}</label>
                                <input
                                    value={values.firstName}
                                    onChange={e => valueSetter({ name: "firstName", value: e.target.value })}
                                    className={classNames("form-control", error(InvitationFormValidators.firstName).isValid ? null : "validation-warning")}
                                    type="text" />

                                {
                                    !error(InvitationFormValidators.firstName).isValid &&
                                    <InputWarning messageKey="firstNameRequired" />
                                }
                            </div>
                            <div className="row">
                                <label>{vocab["LastName"]}</label>
                                <input
                                    value={values.lastName}
                                    onChange={e => valueSetter({ name: "lastName", value: e.target.value })}
                                    className={classNames("form-control", error(InvitationFormValidators.lastName).isValid ? null : "validation-warning")}
                                    type="text" />

                                {
                                    !error(InvitationFormValidators.lastName).isValid &&
                                    <InputWarning messageKey="lastNameRequired" />
                                }
                            </div>
                            <hr />
                            <div className="row buttons right">
                                <button onClick={() => nav(-1 as any)} className="btn cancel">
                                    <span>{vocab["cancel"]}</span>
                                </button>
                                <button disabled={!isValid || sending} onClick={inviteUser} className="btn action">
                                    <span>{vocab["Send"]}</span>
                                </button>
                            </div>
                        </>
                    }


                </div>
            }

            {
                userOtherGroups !== null &&
                <div className={classNames(style.form, "form", "invitation")}>
                    <div className={style.match}>
                        <p><strong>{userOtherGroups.lastName} {userOtherGroups.firstName}</strong> [<i>{values.email}</i>] {localization["membershipText"]}:</p>
                        <ul>
                            {
                                userOtherGroups.groupNames.map(g => {
                                    return <li key={g.key}>{g.value}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={style.buttons}>
                        <button disabled={sending} className="btn cancel" onClick={() => setUserOtherGroups(null)}>{localization["cancel"]}</button>
                        <button disabled={sending} className="btn save" onClick={completeInvitation}>{localization["inviteTo"]} {groupName}</button>
                    </div>
                </div>
            }


        </div>
    );
}