import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames"
import { ReactElement, useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { LocalizationContext } from "../interfaces/AppContext";

import style from "../styles/login.module.scss";

export const PwdCodeVerify = (): ReactElement => {
    const lc = useContext(LocalizationContext);
    const v = lc.localization;

    const nav = useNavigate();
    const [code, setCode] = useState("");

    const onCodeSet = (): void => {
        nav("/resetpwd/" + code);
    };

    const onKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === "Enter") {
            onCodeSet();
        }
    }

    return (
        <div className={classNames(style.container, "page-content")}>
            <div className={classNames("form", "pwdreset", style.resetform)}>
                <h1>{v["PasswordResetPageTitle"]}</h1>

                <div className="row">
                    <p>{v["codeResetInstruction"]}</p>

                    <div className={classNames(style.emailinput,
                        code.length > 4 || code.length === 0 ? null : style.inputwarn,
                    )}>

                        <input
                            onBlur={onCodeSet}
                            placeholder={v["typeCode"]}

                            value={code}
                            onChange={e => setCode(e.target.value)}
                            type="text"
                        />


                        <div className={style.icon}>
                            {
                                code.length > 4 &&
                                <FontAwesomeIcon className={style.chevronBtn} icon={"chevron-right"} />
                            }
                        </div>

                    </div>


                </div>
                <Link to="/resetpwd">{v["noCode"]}</Link>
            </div >
        </div>
    )
}