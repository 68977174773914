import { FC, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { SearchResults } from '../../components/SearchResults/SearchResults';
import { Category } from '../../models/Category';
import { AppContext, LocalizationContext } from '../../interfaces/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IsUndefinedOrNull, IsValidLocation, dateSortDesc, getHashCode, objecToQueryString } from '../../misc/Utilities';
import { AdvancedSearch } from '../../components/Search/AdvancedSearch';
import { Paginator } from '../../components/paginator/Paginator';
import { SearchResultModel } from '../../models/SearchResultModel';
import { homeStore } from '../../store/homeStore';
import { useQuery } from '@tanstack/react-query';
import { searchExchanges } from '../../requests/searchRequests';
import { appKeys } from '../../queryKeys/appKeys';
import style from './home.module.scss';
import ReactLoading from 'react-loading';

export interface HomeProps {
    categories: Array<Category>;
}

const Home: FC<HomeProps> = ({ categories }): ReactElement => {

    const { page, searchHashCode, update, ...model } = homeStore();

    const { data: searchResult, isLoading: areResultsLoading } = useQuery({
        queryFn: () => searchExchanges({ page, ...model }),
        queryKey: appKeys.searchHash(searchHashCode, page)
    });

    const pagesRef = useRef(1);

    if (!areResultsLoading && !IsUndefinedOrNull(searchResult)) {
        pagesRef.current = searchResult.total ?? 1;
    }

    const { person } = useContext(AppContext);
    const { localization } = useContext(LocalizationContext);
    const locationSet = IsValidLocation(person.locationLongitude, person.locationLatitude);
    const [warnLocation, setWarnLocation] = useState(locationSet === false);

    const clearLocationWarning = () => setWarnLocation(false);

    const distanceSorter = (a: SearchResultModel, b: SearchResultModel): number => {
        return a.distanceMeters - b.distanceMeters;
    };

    const dateSorter = (a: SearchResultModel, b: SearchResultModel): number => {
        const dateA = IsUndefinedOrNull(a.exchange.updatedOn) ? a.exchange.publishedOn : a.exchange.updatedOn;
        const dateB = IsUndefinedOrNull(b.exchange.updatedOn) ? b.exchange.publishedOn : b.exchange.updatedOn;
        return dateSortDesc(dateA, dateB);
    };

    const sorter = model.orderBy === "date" ? dateSorter : distanceSorter;

    const sordedResults = searchResult?.results?.sort(sorter) ?? [];

    const onPageChange = (page: number): void => {
        update("page", page);
    };

    return (

        <div className={style.container}>
            {
                warnLocation &&
                <div className={style.warning}>
                    <button onClick={clearLocationWarning}>
                        <FontAwesomeIcon icon={"x"} />
                    </button>
                    <p>{localization["locationNotSetWarning"]} <Link to={"/profile/edit"}>{localization["addLocation"]}</Link></p>
                </div>
            }
            {
                <AdvancedSearch
                    radiusAvailable={locationSet}
                    categories={categories} />
            }

            <div className={style.results}>
                {
                    areResultsLoading ? (<div className={style.spinner}><ReactLoading type={"spin"} color={"#5270F0"} height={64} width={64} /></div>)
                        : (<SearchResults
                            radiusAvailable={locationSet}
                            results={sordedResults} />)
                }
            </div>

            <div className={style.paging}>
                <Paginator
                    currentPage={page}
                    totalPages={pagesRef.current}
                    onPageChange={page => onPageChange(page)}
                    controlLocation={"space-between"}
                    maxPagesToShow={5}
                />
            </div>
        </div >
    );
}

export { Home }