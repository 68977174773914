import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useContext } from "react";
import { LocalizationContext } from "../interfaces/AppContext";

import style from "../styles/inputwarning.module.scss";

export interface InputWarningProps {
    messageKey: string;
}
export const InputWarning = (props: InputWarningProps): ReactElement => {


    const vContext = useContext(LocalizationContext);
    const v = vContext.localization;

    let msg = v[props.messageKey];
    if (typeof msg === "undefined") {
        msg = props.messageKey;
    }
    return (
        <div className={style.warning}>
            <FontAwesomeIcon icon={"exclamation-circle"} />
            <span>{msg}</span>
        </div>
    );
}