import React, { ReactElement, useContext, useState } from "react"
import { UserManifestReport } from "../../models/UserManifestReport"

import style from "./usermanifest.module.scss";
import { HasPrivilege, IsNullOrWhiteSpace, IsUndefinedOrNull, dateSortDesc } from "../../misc/Utilities";
import moment from "moment";
import { ExchangeState, GroupPrivileges } from "../../models/Enums";
import { Category } from "../../models/Category";
import { LocalizationContext } from "../../interfaces/AppContext";
import classNames from "classnames";




export const UserManifestView = ({ model, categories }: { model: UserManifestReport, categories: Category[] }): ReactElement => {

    const [historyFilter, setHistoryFilter] = useState("");

    const { localization } = useContext(LocalizationContext);

    const componentFromSubObjectProps = (obj: object): ReactElement => {
        let props = Object.getOwnPropertyNames(obj);

        return (
            <>
                {
                    props.map((prop: string) => {
                        let current = obj[prop] as string;
                        if (IsNullOrWhiteSpace(current)) {
                            current = "n/a";
                        }
                        return (
                            <div key={prop}>
                                <strong>{prop}:</strong>
                                <span className={current.toLowerCase() === "error" ? style.error : null}>{current}</span>
                            </div>
                        );

                    })
                }
            </>
        );

    };

    const componentFromObjectProps = (obj: object): ReactElement => {

        if(IsUndefinedOrNull(obj)){
            return null;
        };

        let props = Object.getOwnPropertyNames(obj);

        return (
            <div>
                {
                    props.map((prop: string) => {

                        const current = obj[prop];
                        if (typeof current === "object") {
                            return (
                                <div className={style.dr} key={prop}>
                                    <strong>{prop}</strong>
                                    <div className={style.sub}>
                                        {
                                            componentFromSubObjectProps(current)
                                        }
                                    </div>

                                </div>
                            );
                        }
                        else {
                            return (
                                <div className={style.dr} key={prop}>
                                    <strong>{prop}</strong>
                                    {
                                        current.toLowerCase && current.toLowerCase() === "error" &&
                                        <span className={style.error}>{current}</span>
                                    }
                                    {
                                        current.toLowerCase && current.toLowerCase() === "warning" &&
                                        <span className={style.warning}>{current}</span>
                                    }
                                    {
                                        !current.toLowerCase || (current.toLowerCase() !== "error" && current.toLowerCase() !== "warning") &&
                                        <span >{current}</span>
                                    }
                                </div>
                            );
                        }
                    })
                }
            </div>
        );
    };

    return (
        <div className={style.container}>
            <fieldset>
                <legend>Account</legend>
                {
                    componentFromObjectProps(model.personInfo)
                }
            </fieldset>
            <fieldset>
                <legend>History</legend>
                <input type="text" value={historyFilter} onChange={e => setHistoryFilter(e.target.value)} placeholder="quick filter" />
                <div className={classNames(style.limiter, style.togglerowcolor)}>
                    {
                        model.history
                            .sort((a, b) => dateSortDesc(a.createdOn, b.createdOn))
                            .filter(p => {
                                if (historyFilter === "") return p;
                                try {
                                    let xp = new RegExp(historyFilter);
                                    return xp.test(p.action) || xp.test(p.createdByName) || xp.test(p.createdBy);
                                }
                                catch { return p; }
                            })
                            .map(h => {
                                return <div className={style.history} key={h.id}>
                                    {
                                        componentFromObjectProps(h)
                                    }
                                </div>
                            })
                    }
                </div>
            </fieldset>

            <fieldset>
                <legend>Logs</legend>
                <div className={style.limiter}>
                    {
                        model.logs.map(log => {
                            return <div key={log.id}>{componentFromObjectProps(log)}</div>
                        })
                    }
                    {
                        model.backgroundLogs.map(log => {
                            return <div key={log.id}>{componentFromObjectProps(log)}</div>
                        })
                    }
                    {
                        model.logs.length === 0 &&
                        model.backgroundLogs.length === 0 &&
                        <p>no logs</p>
                    }
                </div>
            </fieldset>

            <fieldset>
                <legend>Invitations</legend>
                <div className={style.limiter}>

                    {
                        model.invitations.map(inv => {
                            return <div key={inv.id}>{componentFromObjectProps(inv)}</div>
                        })

                    }
                    {
                        model.invitations.length === 0 &&
                        <p>no invitations</p>
                    }
                </div>
            </fieldset>

            <fieldset>
                <legend>Password resets</legend>
                <div className={style.limiter}>
                    {
                        model.passwordResetRequests.map(pwd => {
                            return <div key={pwd.id}>{componentFromObjectProps(pwd)}</div>
                        })
                    }
                    {
                        model.passwordResetRequests.length === 0 &&
                        <p>no password resets</p>
                    }
                </div>
            </fieldset>

            <fieldset>
                <legend>Groups</legend>
                <div className={style.limiter}>
                    {
                        model.groupMembership.map(gp => {

                            return (
                                <div style={{ marginBottom: "10px" }} key={gp.group.id}>
                                    <div className={style.dr}>
                                        <strong>Group name</strong>
                                        <span style={{ color: "#1f2cd4" }}>{gp.group.name}</span>
                                    </div>
                                    <div className={style.dr}>
                                        <strong>Join date</strong>
                                        <span>{moment(gp.settings.dateJoin).format("ddd DD MMMM, yyyy HH:mm")}</span>
                                    </div>
                                    <div className={style.dr}>
                                        <strong>Privileges</strong>
                                        <div>
                                            {
                                                HasPrivilege(gp.settings.privileges, GroupPrivileges.CanSearch) &&
                                                <span>search</span>
                                            }
                                            {
                                                HasPrivilege(gp.settings.privileges, GroupPrivileges.CanPost) &&
                                                <span>post</span>
                                            }
                                            {
                                                HasPrivilege(gp.settings.privileges, GroupPrivileges.Admin) &&
                                                <span>admin</span>
                                            }
                                            {
                                                HasPrivilege(gp.settings.privileges, GroupPrivileges.Facilitator) &&
                                                <span>facilitator</span>
                                            }
                                        </div>


                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        model.groupMembership.length === 0 &&
                        <p>no group membership</p>
                    }
                </div>
            </fieldset>

            <fieldset>
                <legend>Exchanges</legend>
                <div className={classNames(style.limiter, style.togglerowcolor)}>
                    {
                        model.exchanges.map(ex => {

                            const primaryCategory = categories.find(p => p.id === ex.categoryId);

                            return (
                                <div style={{ padding: "10px" }} key={ex.id}>
                                    <div className={style.dr}>
                                        {
                                            ex.exchangeType === "offer" &&
                                            <strong>Offer title</strong>
                                        }
                                        {
                                            ex.exchangeType === "demande" &&
                                            <strong>Demande title</strong>
                                        }
                                        <span style={{ color: "#1f2cd4" }}>{IsNullOrWhiteSpace(ex.title) ? "** no title" : ex.title}</span>
                                    </div>
                                    <div className={style.dr}>
                                        <strong>State</strong>
                                        <span>{ExchangeState[ex.state]}</span>
                                    </div>

                                    <div className={style.dr}>
                                        <strong>Created on</strong>
                                        <span>{moment(ex.publishedOn).format("ddd DD MMMM, yyyy HH:mm")}</span>
                                    </div>
                                    {
                                        !IsNullOrWhiteSpace(ex.updatedOn) &&
                                        <div className={style.dr}>
                                            <strong>Uodated on</strong>
                                            <span>{moment(ex.updatedOn).format("ddd DD MMMM, yyyy HH:mm")}</span>
                                        </div>
                                    }
                                    <div className={style.dr}>
                                        <strong>Groups</strong>
                                        <span>{ex.groupIds.join(",")}</span>
                                    </div>
                                    <div className={style.dr}>
                                        <strong>Has files</strong>
                                        {
                                            ex.attachments.length > 0 &&
                                            <span>{ex.attachments.length}</span>
                                        }
                                        {
                                            ex.attachments.length === 0 &&
                                            <span>no files</span>
                                        }
                                    </div>
                                    {
                                        !IsUndefinedOrNull(primaryCategory) &&
                                        <div className={style.dr}>
                                            <strong>Category</strong>
                                            <span>{localization[primaryCategory.labelKey]}</span>
                                        </div>
                                    }
                                    {
                                        IsUndefinedOrNull(primaryCategory) &&
                                        <div className={style.dr}>
                                            <strong>Category</strong>
                                            <span>{ex.categoryId}</span>
                                        </div>
                                    }

                                </div>
                            );
                        })
                    }
                    {
                        model.groupMembership.length === 0 &&
                        <p>no exchanges</p>
                    }
                </div>
            </fieldset>
        </div >
    );
}