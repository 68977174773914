import axios from "axios";
import { app } from "..";
import { GroupPrivileges } from "../models/Enums";
import { GroupUser } from "../models/GroupUser";

export const renameGroup = async (groupId: string, name: string): Promise<void> => {
    try {
        await axios.post(`${app.apiBasePath}/admin/group/rename`, {
            groupId: groupId,
            groupName: name
        });
    }
    catch (e) {
        console.warn(e);
    }

}

export const makeFacilitator = async (groupId: string, user: GroupUser): Promise<void> => {
    try {
        await axios.post(`${app.apiBasePath}/admin/group/updateprivileges`, {
            personId: user.personId,
            groupId: groupId,
            justification: '',
            privileges: user.privileges | GroupPrivileges.Facilitator
        });
    }
    catch (e) {
        console.warn(e);
    }
}

export const removeFacilitator = async (groupId: string, user: GroupUser): Promise<void> => {
    try {
        await axios.post(`${app.apiBasePath}/admin/group/updateprivileges`, {
            personId: user.personId,
            groupId: groupId,
            justification: '',
            privileges: user.privileges & ~GroupPrivileges.Facilitator
        });
    }
    catch (e) {
        console.warn(e);
    }
}

export const deleteGroup = async (groupId: string): Promise<void> => {
    try {
        await axios.post(`${app.apiBasePath}/admin/group/delete`, {
            groupId: groupId
        });
    }
    catch (e) {
        console.warn(e);
    }
}
