import { ReactElement, useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { app } from "../..";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { Group } from "../../models/Group";
import { GroupUsersManage } from "./GroupUsersManage";


import { UserManage } from "./UserManage";
import { GroupUser } from "../../models/GroupUser";
import axios from "axios";
import { Invitations } from "./Invitations";

import style from "./groupmanage.module.scss";
import { Publications } from "./Publications";
import { HasPrivilege } from "../../misc/Utilities";
import { Role } from "../../models/Enums";
import classNames from "classnames";
import { appStore } from "../../store/appStore";


export const GroupManage = (): ReactElement => {

    const { groups } = appStore();

    const params = useParams();
    const groupId = params["groupid"];
    const optionId = params["option"];
    const suboption = params["suboption"];

    const [group, setGroup] = useState(null as Group);
    const { localization } = useContext(LocalizationContext);
    const { person } = useContext(AppContext);
    const vocab = localization;


    const [users, setUsers] = useState([] as GroupUser[]);

    useEffect(() => {

        setGroup(groups.find(g => g.id === groupId));

        const request = axios.get(`${app.apiBasePath}/groupadmin/${groupId}/listusers`);

        request.then(result => {
            setUsers(result.data);
        });
    }, [groupId]);

    const refreshUserList = (): void => {
        const request = axios.get(`${app.apiBasePath}/groupadmin/${groupId}/listusers`);
        request.then(result => {
            setUsers(result.data);
        });
    };

    if (group == null) {
        return null;
    }

    return (
        <div className={style.container}>
            <div className={classNames(style.heading, "form")}>
                <h1>{vocab["manage"]} {group.name}</h1>
            </div>

            {
                typeof suboption === "undefined" &&
                <section className={style.options}>
                    <NavLink className={({ isActive }) => {
                        return isActive ? style.linkactive : null;
                    }} to={`/group/manage/${groupId}/users`}>{vocab["users"]}</NavLink>

                    <NavLink className={({ isActive }) => {
                        return isActive ? style.linkactive : null;
                    }} to={`/group/manage/${groupId}/invitations`}>{vocab["invited"]}</NavLink>


                    <NavLink className={({ isActive }) => {
                        return isActive ? style.linkactive : null;
                    }} to={`/group/manage/${groupId}/publish`}>{localization["publishTabName"]}</NavLink>


                    {
                        false &&
                        <NavLink className={({ isActive }) => {
                            return isActive ? style.linkactive : null;
                        }} to={`/group/manage/${groupId}/settings`}>{vocab["settings"]}</NavLink>

                    }
                </section>
            }
            {
                typeof suboption === "string" &&
                <section className={style.options}>
                    <NavLink to={-1 as any} className={({ isActive }) => {
                        return isActive ? style.linkactive : null;
                    }}>{"< retour"}</NavLink>

                </section>
            }
            {
                optionId === "users" &&
                <GroupUsersManage
                    refresh={refreshUserList}
                    users={users}
                    subOption={suboption}
                    groupId={groupId}
                    groupName={group.name} />
            }
            {
                optionId === "user" &&
                <UserManage user={users.find(u => u.personId === suboption)} />
            }
            {
                optionId === "invitations" &&
                <Invitations groupId={groupId} view={suboption} />
            }
            {
                optionId === "publish" &&
                <Publications groupId={groupId} />
            }
        </div>
    );
}