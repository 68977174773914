import { ReactElement, useEffect, useState } from "react";
import style from "./logspage.module.scss";
import { ApiLogProperties, LogEntry } from "./LogEntry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";
import classNames from "classnames";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface PagerProps {
    itemsPerPage: number;
    count: number;
    skipLogs: (s: number) => void;
}

const Pager = (props: PagerProps): ReactElement => {

    const { itemsPerPage, count } = props;

    const [currentIdx, setCurrentIdx] = useState(0);


    let pages = 1;
    if (count > itemsPerPage) {
        let m = count % itemsPerPage
        let n = count / itemsPerPage;
        pages = m === 0 ? n : Math.round(n) + 1;
    }

    const onNext = (): void => {
        if (currentIdx + 1 === pages) return;
        setCurrentIdx(currentIdx + 1);

        if (props.skipLogs) {
            props.skipLogs((currentIdx + 1) * itemsPerPage);
        }

    };

    const onPrev = (): void => {
        if (currentIdx === 0) return;
        setCurrentIdx(currentIdx - 1);

        if (props.skipLogs) {
            props.skipLogs((currentIdx - 1) * itemsPerPage);
        }
    };


    useEffect(() => {
        setCurrentIdx(0);

    }, [props.count]);

    if (count === null)
        return <div className={style.pages}></div>;


    return (
        <div className={style.pager}>
            <button onClick={onPrev}>
                <FontAwesomeIcon icon={"chevron-left"} />
            </button>
            <div>
                <span>{currentIdx + 1} / {pages}</span>
            </div>
            <button onClick={onNext}>
                <FontAwesomeIcon icon={"chevron-right"} />
            </button>
        </div>
    )
};

export const ApiLogsTable = ({ logs }: { logs: Array<LogEntry<ApiLogProperties>> }): ReactElement => {


    const cols = [10, 50, 15, 20, 15];

    const [skip, setSkip] = useState(0);

    let filtered: Array<LogEntry<ApiLogProperties>> = [];

    const [stackTraceLogIds, setStackTraceLogIds] = useState<string[]>([]);

    if (skip > 0) {
        for (let i = skip - 1; i < logs.length; i++) {
            filtered.push(logs[i]);
        }
    }
    else {
        filtered = logs;
    }

    useEffect(() => {
        setSkip(0);
        setStackTraceLogIds([]);

    }, [logs]);

    const hideStackTrace = (logId: string): void => {
        setStackTraceLogIds(prev => {
            const update = prev.filter(p => p !== logId);
            return [...update];
        });

    };
    const showStackTrace = (logId: string): void => { 
        setStackTraceLogIds(prev => {
            return [...prev, logId];
        });
    };


    return (
        <div className={style.logstable}>
            <table className={style.h}>
                <colgroup>
                    {
                        cols.map((c, i) => {
                            return <col key={i} style={{ width: `${c}%` }} />
                        })
                    }
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            level
                        </th>
                        <th>
                            message
                        </th>

                        <th>
                            user
                        </th>
                        <th>
                            context
                        </th>
                        <th>
                            time
                        </th>
                    </tr>
                </thead>
            </table>
            <div className={style.overflow}>
                <table className={style.d}>
                    <colgroup>
                        {
                            cols.map((c, i) => {
                                return <col key={i} style={{ width: `${c}%` }} />
                            })
                        }
                    </colgroup>
                    <tbody>
                        {
                            filtered.map(log => {

                                const stackTraceVisible = stackTraceLogIds.some(p => p === log.id);

                                return (
                                    <tr key={log.id}>
                                        <td className={style.center}>{log.level}</td>
                                        <td>
                                            <textarea defaultValue={log.renderedMessage} />
                                            {
                                                !IsNullOrWhiteSpace(log.exception) &&

                                                <div className={classNames(style.stacktrace, stackTraceVisible === true ? style.show : null)}>
                                                    {

                                                        stackTraceVisible &&
                                                        <div className={style.stack}>
                                                            <span>stack trace</span>
                                                            <FontAwesomeIcon onClick={() => hideStackTrace(log.id)} icon={faChevronUp} />
                                                        </div>

                                                    }
                                                    {
                                                        !stackTraceVisible &&
                                                        <div className={style.stack}>
                                                            <span>stack trace</span>
                                                            <FontAwesomeIcon onClick={() => showStackTrace(log.id)} icon={faChevronDown} />
                                                        </div>
                                                    }
                                                    <div className={style.content}>
                                                        {
                                                            log.exception
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td>{log.properties?.jeuUserEmail ?? ""}</td>
                                        <td><textarea defaultValue={log.properties?.sourceContext} /></td>
                                        <td>{moment(log.timestamp).format("DD MMMM, yyyy HH:mm")}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className={style.f}>
                <Pager itemsPerPage={20} count={logs.length} skipLogs={s => setSkip(s)} />
            </div>
        </div >
    )
}

function useEffeect() {
    throw new Error("Function not implemented.");
}
