import { ReactElement, useEffect, useState, useContext } from "react";
import ReactLoading from "react-loading";
import style from "./vocabulary.module.scss";
import axios from "axios";
import { app } from "../../..";
import { Vocabulary, VocabularyScope } from "../../../models/Vocabulary";
import { OptionType } from "../../../interfaces/OptionType";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCodes } from "http-status-codes";
import { HasPrivilege, IsUndefinedOrNull } from "../../../misc/Utilities";
import { Role } from "../../../models/Enums";
import { AppContext, LocalizationContext } from "../../../interfaces/AppContext";
import { Link, useParams } from "react-router-dom";

type VocabularyExtended = Vocabulary & { isEdit: boolean };

export const VocabularyPage = (): ReactElement => {

    const param = useParams();
    const { localization } = useContext(LocalizationContext);

    const [working, setWorking] = useState(true);
    const [vocab, setVocab] = useState<VocabularyExtended[]>([]);
    const [filter, setFilter] = useState("");
    const [updater, setUpdater] = useState<VocabularyExtended>(null);

    const options: OptionType[] = [{
        label: "Français",
        value: "fr"
    }, {
        label: "English",
        value: "en"
    }];

    const { person } = useContext(AppContext);


    let initial = options[0];
    if (!IsUndefinedOrNull(param.locale)) {
        const p = options.find(x => x.value === param.locale);
        if (p) {
            initial = p;
        }
    }


    const [currentLang, setCurrentLang] = useState<OptionType>(initial);


    useEffect(() => {
        setWorking(true);
        axios.get(`${app.apiBasePath}/admin/vocabulary?locale=${currentLang.value}`).then(result => {

            const filteredVocab = result.data.filter(r => r.scope === VocabularyScope.UserInterface);

            setVocab(filteredVocab.map((d: VocabularyExtended) => {
                d.isEdit = false;
                return d;
            }));
            setWorking(false);
        });

    }, [currentLang]);

    const onLangChange = (option: OptionType): void => {
        setCurrentLang(option);
        cancelEdit();
    };

    const setText = (entry: string, vocab: VocabularyExtended): void => {
        const item: VocabularyExtended = {
            key: vocab.key,
            value: entry,
            id: vocab.id,
            isEdit: false,
            culture: vocab.culture
        };

        setUpdater(item);
    };

    const enableEdit = (vocab: VocabularyExtended): void => {

        const item: VocabularyExtended = {
            key: vocab.key,
            value: vocab.value,
            id: vocab.id,
            isEdit: false,
            culture: vocab.culture
        };

        setUpdater(item);

        setVocab(old => {
            const m = old.map(x => {
                x.isEdit = x.id === vocab.id;
                return x;
            });

            return [...m];
        })
    };
    const saveChanges = (): void => {

        setWorking(true);

        const model: Vocabulary = {
            key: updater.key,
            value: updater.value,
            culture: updater.culture,
            id: updater.id
        };

        axios.post(`${app.apiBasePath}/admin/vocabulary/upsert`, model).then(r => {
            if (r.status === StatusCodes.OK) {

                setVocab(prev => {

                    const q = prev.map(d => {
                        d.isEdit = false;
                        if (d.id === model.id) {
                            d.value = model.value;
                        }
                        return d;
                    });
                    return [...q];
                });
                setWorking(false);
                setUpdater(null);
            }
        });

    };

    const deleteEntry = (vocab: VocabularyExtended): void => {
        if (window.confirm(localization["confimDelete"])) {
            setWorking(true);
            axios.post(`${app.apiBasePath}/admin/vocabulary/delete`, {
                id: vocab.id,
                culture: vocab.culture
            })
                .then(r => {
                    setVocab(prev => {
                        const q = prev.filter(d => d.id !== vocab.id);
                        return [...q];
                    });
                    setWorking(false);
                    setUpdater(null);
                });
        };
    };

    const cancelEdit = (): void => {
        setVocab(old => {
            const m = old.map(x => {
                x.isEdit = false;
                return x;
            });

            return [...m];
        });
        setUpdater(null);
    };

    const listFilter = ((elem: VocabularyExtended) => {
        if (filter === "") return elem;
        var reg = new RegExp(filter, 'i');
        return reg.test(elem.key) || reg.test(elem.value);
    });

    return (
        <div className={style.container}>
            <div className={style.heading}>
                <h1>{localization["manageVocabulary"]}</h1>

                {
                    HasPrivilege(person.role, Role.Developper) &&
                    <Link to={"/admin/vocabulary/add/" + currentLang.value}>
                        <FontAwesomeIcon icon={"plus"} />
                    </Link>
                }

            </div>
            <hr />
            <div className={style.opt}>
                <div className={style.lang}><Select onChange={onLangChange} value={currentLang} options={options} /></div>
                <div className={style.filter}>
                    <input type="text" value={filter} disabled={updater !== null} onChange={e => setFilter(e.target.value)} placeholder="filter" />
                    <button disabled={updater !== null} onClick={() => setFilter("")}>
                        <FontAwesomeIcon icon="x" />
                    </button>
                </div>
                {
                    working &&
                    <div><ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} /></div>
                }
            </div>


            <div className={style.control}>
                {
                    vocab.filter(listFilter).map(v => {
                        return (
                            <div className={style.entry} key={v.id}>
                                {
                                    !v.isEdit &&
                                    <input type="text" defaultValue={v.value} readOnly />
                                }
                                {
                                    v.isEdit &&
                                    <input type="text" onChange={q => setText(q.target.value, v)} value={updater.value} />
                                }
                                <div className={style.subcontrols}>
                                    {
                                        !v.isEdit &&
                                        <button disabled={working} onClick={() => enableEdit(v)}>
                                            <FontAwesomeIcon icon={"pen"} />
                                        </button>
                                    }
                                    {
                                        v.isEdit &&
                                        <>
                                            <button onClick={cancelEdit}>
                                                <FontAwesomeIcon className={style.back} icon={"chevron-left"} />
                                            </button>
                                            <button onClick={() => saveChanges()}>
                                                <FontAwesomeIcon className={style.save} icon={"floppy-disk"} />
                                            </button>
                                            {
                                                HasPrivilege(person.role, Role.Developper) &&
                                                <button onClick={() => deleteEntry(v)}>
                                                    <FontAwesomeIcon className={style.delete} icon={"trash"} />
                                                </button>
                                            }
                                        </>
                                    }
                                    <span>{v.key}</span>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}