import moment from "moment";
import { IsUndefinedOrNull, dateSortDesc, stateToText } from "../../misc/Utilities";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { InvitationState } from "../../models/Enums";
import style from "./invitations.module.scss";
import { Invitation } from "../../models/Invitation";
import { ReactElement, useContext, useState } from "react";
import { LocalizationContext } from "../../interfaces/AppContext";
import axios from "axios";
import { app } from "../..";
import { StatusCodes } from "http-status-codes";
import { HistoryEntry } from "../../models/HistoryEntry";


export const InvitationListView = ({ list, filterValue, adminMode }: { list: Invitation[], filterValue: InvitationState[], adminMode?: boolean }): ReactElement => {

    const { localization } = useContext(LocalizationContext);


    const filter = (inv: Invitation): boolean => {
        if (filterValue.length === 0)
            return true;
        return filterValue.some(p => p === inv.state);
    };

    const getCancellationHistory = (inv: Invitation): void => {
        const request = axios.get(`${app.apiBasePath}/history/entry?id=${inv.uniqueShortTokenId}`);
        request.then(rsp => {
            if (rsp.status === StatusCodes.OK) {
                const entry = rsp.data as HistoryEntry;
                if (entry) {
                    window.alert(`${entry.personDoingAction}: ${entry.message}`);
                }
            }
            else if (rsp.status === StatusCodes.NO_CONTENT) {
                window.alert("Entrée d'historique non trouvée");
            }
        });
    };

    return (
        <div className={style.t}>
            <div className={classNames(style.legend, style.onlymobile)}>
                <span className={classNames(style.state, style.s_new)}>{stateToText(InvitationState.New, localization)}</span>
                <span className={classNames(style.state, style.s_open)}>{stateToText(InvitationState.Opened, localization)}</span>
                <span className={classNames(style.state, style.s_cancel)}>{stateToText(InvitationState.Cancelled, localization)}</span>
                <span className={classNames(style.state, style.s_redeem)}>{stateToText(InvitationState.Redeemed, localization)}</span>
                <span className={classNames(style.state, style.s_deletedaccount)}>{stateToText(InvitationState.DeletedAccount, localization)}</span>
            </div>

            <div className={classNames(style.overflow, adminMode ? style.admin : null)}>
                <table>
                    <thead>
                        <tr>
                            <th className={style.onlywidecell}>{localization["FirstName"]}</th>
                            <th>{localization["LastName"]}</th>
                            <th className={style.onlywidecell}>{localization["EmailAddress"]}</th>
                            <th>{localization["DateCreated"]}</th>
                            <th>{localization["DateUpdated"]}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.filter(filter).sort((a, b) => dateSortDesc(a.dateCreated, b.dateCreated)).map((inv: Invitation) => {
                                return (
                                    <tr key={inv.uniqueShortTokenId} className={style.r}>
                                        <td className={style.onlywidecell}>
                                            <span>{inv.lastName}</span>
                                        </td>
                                        <td className={style.onlywidecell}>
                                            <span>{inv.firstName}</span>
                                        </td>
                                        <td className={style.onlymobilecell}>
                                            <span>{inv.lastName}</span>
                                            <span>{inv.firstName}</span>
                                            <div>
                                                <span>{inv.emailAddress}</span>
                                            </div>
                                        </td>
                                        <td className={style.onlywidecell}>
                                            <span>{inv.emailAddress}</span>
                                        </td>
                                        <td>
                                            <span>{moment(inv.dateCreated).format("MMM DD, YYYY")}</span>
                                            <div>
                                                <span>par {inv.createdByName}</span>
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                !IsUndefinedOrNull(inv.dateUpdated) &&
                                                <span>{moment(inv.dateUpdated).format("MMM DD, YYYY")}</span>
                                            }
                                        </td>
                                        <td>
                                            <span className={classNames(style.state,
                                                inv.state === InvitationState.New ? style.s_new : null,
                                                inv.state === InvitationState.Opened ? style.s_open : null,
                                                inv.state === InvitationState.Cancelled ? style.s_cancel : null,
                                                inv.state === InvitationState.Redeemed ? style.s_redeem : null,
                                                inv.state === InvitationState.DeletedAccount ? style.s_deletedaccount : null
                                            )}><span className={style.onlywide}>{stateToText(inv.state, localization)}</span></span>

                                            {
                                                inv.state === InvitationState.Cancelled &&
                                                <div className={style.cancellationreason}>
                                                    <span onClick={x => getCancellationHistory(inv)}>afficher le message</span>
                                                </div>
                                            }

                                        </td>
                                        <td>
                                            {
                                                inv.state !== InvitationState.DeletedAccount &&
                                                inv.state !== InvitationState.Cancelled &&
                                                inv.state !== InvitationState.Redeemed &&
                                                < Link to={inv.uniqueShortTokenId}>
                                                    <FontAwesomeIcon icon={"ellipsis"} />
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div >
    )
}