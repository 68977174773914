import axios, { HttpStatusCode } from "axios";
import { ExchangeDetails } from "../models/ExchangeDetails";
import { app } from "..";
import { StringIsNullOrEmpty } from "../misc/Utilities";



export const getExchangeById = async (id: string): Promise<ExchangeDetails | null> => {
    if (StringIsNullOrEmpty(id)) {
        return null;
    }

    const request = await axios.get<ExchangeDetails>(`${app.apiBasePath}/exchange/view/${id}`);
    if (request.status === HttpStatusCode.Ok) {
        return request.data;
    }
    else return null;
}