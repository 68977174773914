
import { ReactElement, useContext, useEffect, useState } from "react";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { ExchangeDetails } from "../../models/ExchangeDetails";
import moment from "moment";
import { Link } from "react-router-dom";
import { IsNullOrWhiteSpace, StringIsNullOrEmpty } from "../../misc/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Favorites } from "../../components/Favorites/Favorites";
import style from "./sideinfo.module.scss";
import axios from "axios";
import { app } from "../..";
import { StatusCodes } from "http-status-codes";

export interface ExchangeSideInfoProps {
    exchangeInfo: ExchangeDetails;
    hasLocation: boolean;
    onViewLocation?: () => void;
    viewsCount: number;
}

type GroupInfo = {
    id: string;
    name: string;
}

export const ExchangeSideInfo = (props: ExchangeSideInfoProps): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    const { person, favorites } = useContext(AppContext);
    const { exchangeInfo, hasLocation, onViewLocation, viewsCount } = props;

    const [groups, setGroups] = useState<Array<GroupInfo>>([]);

    useEffect(() => {

        const response = axios.get(`${app.apiBasePath}/exchange/usergroups/${exchangeInfo.exchange.publishedBy}`);
        response.then(result => {
            if (result.status === StatusCodes.OK) {
                setGroups(result.data);
            }
        });

    }, []);

    return (

        <div className={style.sideinfo}>

            <div className={style.r}>
                <h5>{localization["publishDate"]}</h5>
                <span>{moment(exchangeInfo.exchange.publishedOn).format("MMM DD, YYYY")}</span>
            </div>

            {
                !IsNullOrWhiteSpace(exchangeInfo.exchange.updatedOn) &&

                <div className={style.r}>
                    <h5>{localization["lastUpdated"]}</h5>
                    <span>{moment(exchangeInfo.exchange.updatedOn).format("MMM DD, YYYY")}</span>
                </div>
            }
            <div className={style.r}>
                {
                    !StringIsNullOrEmpty(exchangeInfo.avatarFileId) &&
                    <div className={style.avatar} title={exchangeInfo.name}>
                        <img src={`${app.apiBasePath}/assets/image/${exchangeInfo.avatarFileId}`} alt={person.displayName} />                        
                    </div>
                }
                <span>{exchangeInfo.name}</span>
            </div>
            {
                person.personId !== exchangeInfo.exchange.publishedBy &&
                <div className={style.r}>


                    <div>
                        <Link to={`/exchanges/u/${exchangeInfo.exchange.publishedBy}`}>{ }</Link>
                    </div>

                    <ul>
                        {
                            groups.map(g => {
                                return <li key={g.id}>{g.name}</li>
                            })
                        }
                    </ul>
                </div>
            }
            <div className={style.r}>
                <h5>{"Contact"}</h5>
                {
                    !StringIsNullOrEmpty(exchangeInfo.primaryContact) &&
                    <div><a href={"tel:" + exchangeInfo.primaryContact}>{exchangeInfo.primaryContact}</a></div>
                }
                {
                    !StringIsNullOrEmpty(exchangeInfo.secondaryContact) &&
                    <div><a href={"tel:" + exchangeInfo.secondaryContact}>{exchangeInfo.secondaryContact}</a></div>
                }
                {
                    !StringIsNullOrEmpty(exchangeInfo.otherContact) &&
                    <div><span>{exchangeInfo.otherContact}</span></div>
                }
                {
                    !StringIsNullOrEmpty(exchangeInfo.email) &&
                    <div>
                        <a href={`mailto:${exchangeInfo.email}?subject=${localization["mailtoSubject"]}&body=${localization["mailtoBody"]}`}>{exchangeInfo.email}</a>
                    </div>
                }
            </div>
            {
                !StringIsNullOrEmpty(exchangeInfo.exchange.webUrl) &&
                <div className={style.r}>
                    <h5>{"Web"}</h5>
                    <a href={`//${exchangeInfo.exchange.webUrl}`} target="_blank">{exchangeInfo.exchange.webUrl}</a>
                </div>
            }
            {
                person.personId !== exchangeInfo.exchange.publishedBy &&
                <div className={style.r}>
                    <Favorites isSet={favorites.some(p => p.exchangeId === exchangeInfo.exchange.id)} resourceId={exchangeInfo.exchange.id} />
                </div>
            }
            <div className={style.r}>
                <span style={{ fontSize: ".9em" }}>{viewsCount} {localization["visits"]}</span>
            </div>
            {
                typeof onViewLocation === "function" &&
                hasLocation &&
                <button className={classNames("btn", "action", style.mapbtn)} onClick={onViewLocation}>
                    <FontAwesomeIcon icon={"location-dot"} />
                    {localization["showMap"]}</button>
            }
        </div>
    )
};
