import axios from "axios";
import { app } from "..";
import { StatusCodes } from "http-status-codes";

export const addFavorite = async (resourceId: string): Promise<void> => {
    const result = await axios.post(`${app.apiBasePath}/profile/favorites/exchanges`, {
        exchangeId: resourceId,
        addToFavorite: true
    });

    if (result.status !== StatusCodes.OK) {
        throw new Error("Error updating favorite");
    }
}

export const removeFavorite = async (resourceId: string): Promise<void> => {
    const result = await axios.post(`${app.apiBasePath}/profile/favorites/exchanges`, {
        exchangeId: resourceId,
        addToFavorite: false
    });

    if (result.status !== StatusCodes.OK) {
        throw new Error("Error updating favorite");
    }
}