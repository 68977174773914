
import { ReactElement } from "react";
import { app } from "../..";
import style from "./style.module.scss";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';

export const Thumbnails = ({ files }: { files: string[] }): ReactElement => {

    return (
        <div className={style.container}>


            <Swiper className={style.images} navigation={true} modules={[Navigation]}>
                {
                    files?.map(file => {
                        return (
                            <SwiperSlide key={file}>
                                {
                                    <img src={`${app.apiBasePath}/assets/image/thumb/${file}`} alt="img" />
                                }
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
};