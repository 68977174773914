import { ReactElement, useContext, useState } from "react";

import style from "./categorymanagement.module.scss";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { app } from "../../..";
import { IsNullOrWhiteSpace } from "../../../misc/Utilities";
import { NotificationView, NotificationType } from "../../../components/NotificationView";
import { PubSubTopic } from "../../../misc/Constants";
import { Message } from "../../../misc/EventMessages";

export const CategoryForm = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [example, setExample] = useState("");
    const [error, setError] = useState("");
    const [working, setWorking] = useState(false);
    const nav = useNavigate();


    const requestCategoryRefresh = (): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RefreshCategories,
            data: {
                locale: "fr"
            }
        });
    };

    const addCategory = (): void => {

        const model = {
            name: name,
            description: description,
            example: example
        };

        setWorking(true);

        axios.post(`${app.apiBasePath}/admin/addcategory`, model)
            .then(result => {
                setWorking(false);
                if (!IsNullOrWhiteSpace(result.data.errorMessage)) {
                    setError(result.data.errorMessage);
                }
                requestCategoryRefresh();
                nav("/admin/categories/");
            }).catch(e => {
                console.warn(e);
                setWorking(false);
            });
    };

    return (
        <div className={style.container}>
            <div className={classNames("form", style.form)}>
                <h1>{localization["categoryManagement"]}</h1>

                <div className="row">
                    <label>{localization["categoryName"]}</label>
                    <input disabled={working} value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="row">
                    <label>{localization["categoryDescription"]}</label>
                    <input disabled={working} value={description} onChange={e => setDescription(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="row">
                    <label>{localization["categoryExample"]}</label>
                    <input disabled={working} value={example} onChange={e => setExample(e.target.value)} type="text" className="form-control" />
                </div>

                <div className="row buttons">
                    {
                        !working &&
                        <Link className="btn cancel" to="/admin/categories">{localization["cancel"]}</Link>
                    }
                    <button disabled={working || name.trim().length === 0} className="btn save" onClick={addCategory}>
                        <FontAwesomeIcon className="before" icon="floppy-disk" />
                        <span>{localization["save"]}</span>
                    </button>
                </div>

                {
                    !IsNullOrWhiteSpace(error) &&
                    <NotificationView type={NotificationType.Error}>
                        <p>{error}</p>
                    </NotificationView>
                }
            </div>
        </div>
    )
};