import { ReactElement, useEffect } from "react";

import style from "./popup.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface PopupProps {
    onClose?: () => void;
    onBeforeClose?: () => boolean;
    title: string;
    children?: ReactElement;
}

export const Popup = (props: PopupProps): ReactElement => {

    const { title, children, onClose, onBeforeClose } = props;

    const closePopup = (): void => {

        let closeIt = true;
        if (typeof onBeforeClose === "function") {
            closeIt = onBeforeClose();
        }
        closeIt && typeof onClose === "function" && onClose();
    };

    useEffect(() => {
        function keyMonitor(key) {
            key.code === "Escape" && closePopup();
        }
        window.addEventListener("keydown", keyMonitor);

        return () => {
            window.removeEventListener("keydown", keyMonitor);
        }
    }, []);


    return (


        <div className={style.popup}>

            <div className={style.content}>
                <header>
                    <h4>{title}</h4>
                    <button onClick={closePopup}>
                        <FontAwesomeIcon icon={"x"} />
                    </button>
                </header>
                <div className={style.body}>
                    {
                        children
                    }
                </div>
            </div>

        </div>
    );

}