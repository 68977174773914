import { ReactElement, useState } from "react";
import ReactLoading from "react-loading";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/EventMessages";
import axios from "axios";
import { app } from "../..";
import { UserManifestReport } from "../../models/UserManifestReport";
import { UserManifestView } from "./UserManifestView";
import style from "./userdebug.module.scss"
import { Category } from "../../models/Category";
import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../../misc/Utilities";

export interface UserDebugViewProps {
    query: string;
    report?: UserManifestReport;
    categories: Category[];
}

export const UserDebugView = (props: UserDebugViewProps): ReactElement => {

    const { query, report, categories } = props;

    const [working, setWorking] = useState(false);

    const debounceUpdateQuery = (val: string): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.SetProfileDebugQueyText,
            data: {
                query: val
            }
        });
    };

    let tmref = 0;
    const updateQuery = (val: string): void => {
        window.clearTimeout(tmref);
        tmref = window.setTimeout(() => { debounceUpdateQuery(val); }, 200);
    };

    const getManifest = (): void => {
        setWorking(true);
        const request = axios.get(`${app.apiBasePath}/admin/logs/report/user?emailAddress=${query}`);
        request.then(result => {
            setWorking(false);

            if (!IsUndefinedOrNull(result.data)) {
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.SaveProfileDebugResult,
                    data: result.data as UserManifestReport
                });
            }
        });
    };

    return (
        <div className={style.container}>
            <h1>Get user manifest</h1>
            <div className={style.input}>
                <input
                    type="text"
                    defaultValue={query}
                    onChange={e => updateQuery(e.target.value)}
                    placeholder="type email"
                    className="form-control" />

                <button disabled={working || IsNullOrWhiteSpace(query)} className="btn action" onClick={getManifest}>
                    Get
                </button>
            </div>

            {
                working &&
                <ReactLoading height={28} type={"bars"} color={"#5270F0"} width={28} />
            }

            {
                report !== null &&
                <UserManifestView model={report} categories={categories} />
            }
        </div>
    )
};