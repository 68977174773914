import { ReactElement } from "react";

import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { AnonymousMaster } from "./AnonymousMaster";
import { Login } from "./Login";
import { PasswordRecovery } from "./PasswordRecovery";
import { PasswordResetForm } from "./PasswordResetForm";
import { PwdCodeVerify } from "./PwdCodeVerify";
import { SetupAccount } from "./SetupAccount";
import { WelcomePage } from "./WelcomePage";
import { AccountActivated } from "./AccountConfirmation/AccountActivated";
import { ActivationFailure } from "./AccountConfirmation/ActivationFailure";

export const GateApp = (): ReactElement => {

    return (

        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<AnonymousMaster />}>
                    <Route index element={<Login redirectTo={window.location.pathname} />} />
                    <Route path={"/resetpwd"}>
                        <Route index element={<PasswordRecovery />} />
                        <Route path={":code"} element={<PasswordResetForm />} />
                    </Route>
                    <Route path={"bienvenue"}>
                        <Route index element={<WelcomePage />} />
                        <Route path={":code"} element={<SetupAccount />} />
                    </Route>
                    <Route path="failure/confirm" element={<ActivationFailure />} />
                    <Route path="confirmed" element={<AccountActivated />} />

                    <Route path={"resetwithcode"}>
                        <Route index element={<PwdCodeVerify />} />
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};