import React from 'react';
import style from '../styles/progressbar.module.scss';


export interface ProgressBarProps{
    percent?: number;
    height?: string;
    domId?: string;
}
const ProgressBar = (props: ProgressBarProps) : JSX.Element => {
    return (
        <div className={style.progressbar}>
            {
                !isNaN(props.percent) &&
                <div id={props.domId} style={{width:props.percent + '%', height: props.height}}></div>
            }
            {
                isNaN(props.percent) &&
                <div id={props.domId}></div>
            }
        </div>
    );
};

export {ProgressBar}