import classNames from "classnames";
import { ReactElement, useContext, useEffect, useState } from "react"
import { LocalizationContext } from "../interfaces/AppContext";
import ReactLoading from "react-loading";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { app } from "..";
import { InputWarning } from "../components/InputWarning";

import style from "../styles/login.module.scss";

export const PasswordResetForm = (): ReactElement => {

    const param = useParams();
    const code = param["code"];

    const lc = useContext(LocalizationContext);
    const v = lc.localization;


    const [pwd1, setPWD1] = useState("");
    const [pwd2, setPWD2] = useState("");

    const [errorCode, setErrorCode] = useState("");

    const [trying, setTrying] = useState(false);

    const [canReset, setCanReset] = useState(false);

    const nav = useNavigate();

    useEffect(() => {

        const request = axios.post(`${app.apiBasePath}/public/pwd/testcode`, {
            code: code
        });

        request.then(result => {
            if (result.data?.pass !== true) {
                nav("/resetpwd");
            }
            else setCanReset(true);
        });

        request.catch(e => {

            console.warn(e, "failure");
        });

    }, [code]);


    const resetPassword = (): void => {
        if (trying) { return; }

        setTrying(true);

        const request = axios.post(`${app.apiBasePath}/public/pwd/changepwd`, {
            newPassword: pwd1,
            code: code
        });

        request.then(result => {

            setTrying(false);

            if (result.data?.success === true) {
                nav("/");
            }
            else {
                setErrorCode(result.data.errorCode);
            }
        });

        request.catch(e => {
            setTrying(false);
            console.warn(e);
        });
    };


    if (!canReset) {
        return (
            <div className="page-content">
                <div className={classNames("form", "pwdreset", style.resetform)}>
                    <h1>{v["PasswordResetPageTitle"]}</h1>
                    <div className={style.loading}>
                        <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                    </div>
                </div>
            </div>
        );
    }


    return (

        <div className="page-content">
            <div className={classNames("form", "pwdreset", style.resetform)}>
                <h1>{v["PasswordResetPageTitle"]}</h1>

                <div className="row">
                    <label>{v["TypeNewPassword"]}</label>
                    <input
                        disabled={trying}
                        type="password"
                        value={pwd1}
                        onChange={e => setPWD1(e.target.value)}
                        className="form-control" />

                    {
                        errorCode === "0x11" &&
                        <div className="row">
                            <InputWarning messageKey="PasswordNotStrong" />
                        </div>
                    }
                </div>

                <div className="row">
                    <label>{v["RetypeNewPassword"]}</label>
                    <input
                        value={pwd2}
                        onChange={e => setPWD2(e.target.value)}
                        disabled={trying}
                        type="password"
                        className="form-control" />
                </div>
                {
                    !trying &&
                    <div className={style.buttonrow}>
                        <button className="btn cancel" onClick={() => nav("/")}>{v["cancel"]}</button>
                        <button onClick={resetPassword} className="btn action">{v["Update"]}</button>
                    </div>
                }

                <div>
                    {
                        trying &&
                        <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                    }
                </div>
            </div>

        </div >
    );
}