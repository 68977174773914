import { ReactElement } from "react";
import style from "./accountconfirm.module.scss";
import { Link } from "react-router-dom";
export const AccountActivated = (): ReactElement => {

    return (
        <div className={style.container}>
            <h1>Merci d'avoir confirmé votre email!</h1>

            <div className={style.home}>
                <Link to="/">Page d'acceuil</Link>
            </div>
        </div>
    )
};