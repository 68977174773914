import axios from "axios";
import { ReactElement, useEffect, useRef, useState } from "react"
import { useParams } from "react-router";
import { app } from "..";
import { Category } from "../models/Category";
import { Exchange } from "../models/Exchange";
import ReactLoading from "react-loading";
import { ExchangeForm } from "../pages/post/ExchangeForm";
import { PubSubTopic } from "../misc/Constants";
import { AppState } from "../interfaces/AppState";

export interface ExchangeLoaderProps {
    categories: Category[];
}

export const ExchangeLoader = (props: ExchangeLoaderProps): ReactElement => {

    const { exchangeid } = useParams();

    const [exchange, setExchange] = useState(null as Exchange);
    const exchangeState = useRef();

    useEffect(() => {

        const rq = axios.get(`${app.apiBasePath}/exchange/${exchangeid}`);
        rq.then(r => {
            setExchange(r.data);
            exchangeState.current = r.data.state;
        });


        const onStoreDataChanged = (msg, state: AppState): void => {
            const exchange = state.exchanges.find(p => p.id === exchangeid);

            if (exchange !== null) {
                // check here for bug
                if (exchange.state !== exchangeState.current) {
                    setExchange(exchange);
                }
            }
        };

        const ref = PubSub.subscribe(PubSubTopic.Changes, onStoreDataChanged);

        return () => {
            PubSub.unsubscribe(ref);
        };

    }, [exchangeid]);


    if (exchange === null) {
        return <div style={{ padding: "20px" }}><ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} /></div>
    }

    return <ExchangeForm {...exchange} categories={props.categories} />
}