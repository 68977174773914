import React from "react";
import { useContext } from "react";
import { Checkbox } from "../../components/Checkbox";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";

import style from '../../styles/checkbox.module.scss';

export interface ContactPrivacyOptionsProps {

    sharePrimaryContact: boolean,
    shareSecondaryContact: boolean,
    shareEmailContact: boolean,
    shareOtherContact: boolean,
    otherContactText: string,
    onCheckChange: (control: string, checked: boolean) => void;
    onOtherTextChange: (value: string) => void;
}

export const ContactPrivacyOptions = (props: ContactPrivacyOptionsProps): JSX.Element => {

    const { person } = useContext(AppContext);

    const { localization } = useContext(LocalizationContext);

    return (
        <React.Fragment>
            {
                !IsNullOrWhiteSpace(person.primaryNumber) &&
                <div className={style.cbrow}>
                    <Checkbox
                        label={person.primaryNumber}
                        checked={props.sharePrimaryContact}
                        onCheckChange={props.onCheckChange.bind(this, 'sharePrimaryContact')} />
                </div>
            }
            {
                !IsNullOrWhiteSpace(person.secondaryNumber) &&
                <div className={style.cbrow}>

                    <Checkbox
                        label={person.secondaryNumber}
                        checked={props.shareSecondaryContact}
                        onCheckChange={props.onCheckChange.bind(this, 'shareSecondaryContact')} />
                </div>
            }
            {
                !IsNullOrWhiteSpace(person.email) &&
                <div className={style.cbrow}>
                    <Checkbox
                        label={person.email}
                        checked={props.shareEmailContact}
                        onCheckChange={props.onCheckChange.bind(this, 'shareEmailContact')} />
                </div>
            }
            <div className={style.cbrow}>
                <Checkbox
                    label={localization["otherContact"]}
                    checked={props.shareOtherContact}
                    onCheckChange={props.onCheckChange.bind(this, 'shareOtherContact')} />

                {
                    props.shareOtherContact &&
                    <div className="subcheck-options">
                        <div className="arrow"></div>
                        <input
                            value={props.otherContactText}
                            onChange={(e) => { props.onOtherTextChange(e.target.value) }}
                            type="text"
                            className="form-control"
                            placeholder={localization["otherContactPlaceholder"]} />
                    </div>
                }
            </div>


        </React.Fragment>

    )
}; 