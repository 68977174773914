import { ReactElement, ReactNode, useMemo, useRef, useState } from "react";
import style from "./baloon.module.scss";
import { IsMobile } from "../../misc/Utilities";



export const Baloon = ({ text, children }: { text: string | JSX.Element, children: ReactNode }): ReactElement => {


    const [show, setShow] = useState(false);

    let showTmeoutref = useRef(null);
    let hideTmeoutref = useRef(null);

    const mobile = IsMobile();


    const mouseOver = (e): void => {
        if (mobile) return;

        window.clearTimeout(hideTmeoutref.current);
        window.clearTimeout(showTmeoutref.current);

        showTmeoutref.current = window.setTimeout(() => {
            setShow(true);
        }, 700);
    };



    const mouseOout = (): void => {
        if (mobile) return;

        window.clearTimeout(showTmeoutref.current);
        window.clearTimeout(hideTmeoutref.current);

        hideTmeoutref.current = window.setTimeout(() => {
            setShow(false);
        }, 300);
    };

    return (

        <div onMouseOver={mouseOver} onMouseOut={mouseOout} className={style.container}>
            {
                show &&
                <div className={style.baloon}>
                    <article>
                        <div className={style.overflow}>
                            {
                                typeof text === "string" &&
                                <p>{text}</p>
                            }
                            {
                                typeof text !== "string" &&
                                text
                            }
                        </div>
                    </article>
                </div>
            }
            {
                children
            }
        </div>
    );
}