
import { HandleType, HandleTypeAsync, LocalEvent } from '../core/interfaces/CoreInterfaces';
import { Message } from "../misc/EventMessages";
import { AppState } from "../interfaces/AppState";
import { Attachment } from '../models/Attachment';
import { GetCategories, GetLocalization } from '../misc/Requests';
import { Locale } from '../models/Locale';
import { UserManifestReport } from '../models/UserManifestReport';
import { appStore } from '../store/appStore';


export class MainHandler {

    constructor() {
        this.AddAttachmentToExchange.message = Message.AddAttachmentToExchange;
        this.RemoveAttachmentFromExchange.message = Message.RemoveAttachmentFromExchange;       
        this.RefreshCategories.message = Message.RefreshCategories;
        this.RefreshVocabulary.message = Message.RefreshVocabulary;
        this.SetProfileDebugQueyText.message = Message.SetProfileDebugQueyText;
        this.SaveProfileDebugResult.message = Message.SaveProfileDebugResult;
    }

    AddAttachmentToExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data.id as string;
        const attachment = arg.data.attachment as Attachment;

        state.exchanges = state.exchanges.map(e => {
            if (e.id === exchangeId) {
                e.attachments.push(attachment);
            }
            return e;
        });

        return true;
    });

    RemoveAttachmentFromExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data.id as string;
        const attachmentId = arg.data.attachment as string;

        state.exchanges = state.exchanges.map(e => {
            if (e.id === exchangeId) {
                e.attachments = e.attachments.filter(x => x.fileId !== attachmentId);
            }
            return e;
        });

        return true;
    });

    RefreshCategories = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const { setLocalization, setCategories } = appStore.getState();
        const locale = arg.data.locale as Locale;
        const vocab = await GetLocalization(locale);
        setLocalization(vocab);
        const categories = await GetCategories();
        setCategories(categories); 
        return Promise.resolve(true);
    });

    RefreshVocabulary = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const { setLocalization } = appStore.getState();
        const locale = arg.data.locale as Locale;
        const vocab = await GetLocalization(locale);
        setLocalization(vocab);
        return Promise.resolve(true);
    });

    SetProfileDebugQueyText = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        state.userDebugQuery = arg.data.query as string;
        return true;
    });

    SaveProfileDebugResult = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        state.userManifest = arg.data as UserManifestReport;
        return true;
    });
}