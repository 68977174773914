import { LocalEvent, Store } from '../core/interfaces/CoreInterfaces';
import { EventRouter } from '../core/EventRouter';
import { LoginEventsHandler } from '../handlers/LoginEventsHandler';
import { MainHandler } from '../handlers/MainHandler';
import { AppState } from '../interfaces/AppState';
import PubSub from 'pubsub-js';
import { PubSubTopic } from '../misc/Constants';
import { ExchangeHandler } from '../handlers/ExchangeHandler';
import { SocketMessageHandler } from '../handlers/SocketMessageHandler';

class MainStore implements Store {

    state: AppState = {
        exchanges: [], 
        userDebugQuery: "",
        userManifest: null,
        notifications: [],      
        searchResults: []
    };

    constructor() {
        this.initialize();
    }

    initialize(): void {

        let router = new EventRouter({ isDebug: false });

        let lh = new LoginEventsHandler();
        let mh = new MainHandler();
        let eh = new ExchangeHandler();
        let smh = new SocketMessageHandler();

        router.addHandler(lh);
        router.addHandler(mh);
        router.addHandler(eh);
        router.addHandler(smh);

        PubSub.subscribe(PubSubTopic.Action, async (msg, data: LocalEvent) => {

            let result = router.route(data, this.state);

            if (result instanceof Promise) {
                await result && this.emitChanges();
            }
            else {
                result === true && this.emitChanges();
            }
        });
    }


 
    emitChanges(): void {
        PubSub.publish(PubSubTopic.Changes, this.state);
    }
}

export { MainStore };