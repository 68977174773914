/**
 * Paginator component for navigating through pages.
 *
 * @component
 * @example
 * ```tsx
 * <Paginator
 *    page={currentPage}
 *    totalPages={totalPages}
 *    maxPagesToShow={10}
 *    onPageChange={handlePageChange}
 *    onPageSelect={handlePageSelect}
 * />
 * ```
 */
import { FC, ReactElement, useRef } from "react";
import style from "./paginator.module.scss";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { generatePageNumbers } from "./helpers";
import { IsFunction } from "../../misc/Utilities"; 


/**
 * page: Current page number.
 * totalPages: Total number of pages.
 * maxPagesToShow: Maximum number of pages to show.
 * onPageChange: Function to call when a page is changed.
 * onPageSelect: Function to call when a page is selected. This call is debounced by 400ms.
 */

export type ControlLocation = "center" | "space-between";

export interface PaginatorProps {
    currentPage: number;
    totalPages: number;
    maxPagesToShow?: number;
    disabled?: boolean;
    controlLocation?: ControlLocation;
    onPageChange: (page: number) => void;
    onPageSelect?: (page: number) => void;
}


export const Paginator: FC<PaginatorProps> = (
    {
        currentPage,
        totalPages,
        maxPagesToShow,
        disabled,
        controlLocation,
        onPageChange,
        onPageSelect }): ReactElement => {



    const timeeoutRef = useRef<number | null>(null);

    let pagesToShow = maxPagesToShow || 8;
    if (pagesToShow > totalPages) {
        pagesToShow = totalPages;
    }

    const plusTen = (): ReactElement => {
        const lastPage = pages[pages.length - 1];
        return (<>
            <span>...</span>
            <button onClick={() => onNavButtonClick(lastPage + 10)}>{lastPage + 10}</button>
        </>);
    }

    const minusTen = (): ReactElement => {
        const firstPage = pages[0];
        return (
            <>
                <button onClick={() => onNavButtonClick(firstPage - 10)}>{firstPage - 10}</button>
                <span>...</span>
            </>
        )
    }

    const pages = generatePageNumbers(currentPage, pagesToShow, totalPages);

    const onNavButtonClick = (nextPage: number): void => {
        onPageChange(nextPage);

        if (IsFunction(onPageSelect)) {
            clearTimeout(timeeoutRef.current || 0);
            timeeoutRef.current = window.setTimeout(() => {
                onPageSelect(nextPage);
            }, 400);
        }
    }

    const containerStyleOverride: React.CSSProperties = {};

    if (controlLocation) {
        containerStyleOverride.justifyContent = controlLocation;
    }

    if (currentPage < 1) {
      //  console.warn("Current page is less than 1. Minumum value is 1.");
    }

    if (currentPage > totalPages) {
      //  console.warn("Current page is greater than total number of pages.");
    }

    if (totalPages < 1) {
        return <></>;
    }


    return (
        <div className={style.paginator} style={containerStyleOverride}>
            <div className={style.nav}>
                {
                    maxPagesToShow && maxPagesToShow < totalPages &&
                    <button
                        title={"Go to page 1"}
                        className={style.limits}
                        onClick={() => onNavButtonClick(1)}
                        disabled={currentPage === 1 || disabled}
                    >
                        <FirstPageIcon />
                    </button>
                }
                <button
                    onClick={() => onNavButtonClick(currentPage - 1)}
                    disabled={currentPage === 1 || disabled}
                >
                    <KeyboardArrowLeftIcon />
                </button>
            </div>

            <div className={style.mid}>
                <div className={style.pages}>
                    {
                        pages[0] - 10 > 1 &&
                        minusTen()
                    }
                    {pages.map((pg: number, index: number) => (
                        <button
                            disabled={disabled}
                            key={index}
                            onClick={() => onNavButtonClick(pg)}
                            className={pg === currentPage ? style.active : null}
                        >
                            {pg}
                        </button>
                    ))}
                    {
                        pages[pages.length - 1] + 10 < totalPages &&
                        plusTen()
                    }
                </div>
            </div>

            <div className={style.nav}>
                <button
                    onClick={() => onNavButtonClick(currentPage + 1)}
                    disabled={currentPage === totalPages || disabled}
                >
                    <KeyboardArrowRightIcon />
                </button>
                {
                    maxPagesToShow && maxPagesToShow < totalPages &&
                    <button
                        title={`Go to page ${totalPages}`}
                        className={style.limits}
                        onClick={() => onNavButtonClick(totalPages)}
                        disabled={currentPage === pages[pages.length - 1] || disabled}
                    >
                        <LastPageIcon />
                    </button>
                }
            </div>
        </div>
    );
}