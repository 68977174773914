import { ReactElement, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IsUndefinedOrNull } from "../../misc/Utilities";
import { GroupUser } from "../../models/GroupUser";
import { InvitationForm } from "./InvitationForm"

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./groupmanage.module.scss";
import { LocalizationContext } from "../../interfaces/AppContext";
import classNames from "classnames";

export default interface GroupUsersManageProps {
    users: GroupUser[];
    groupId: string;
    groupName: string;
    subOption: string;
    refresh: () => void;
}


export const DefaultView = ({ users, refresh }: { users: GroupUser[], refresh: () => void; }): ReactElement => {

    enum SortType {
        date,
        count,
        name
    };

    const { localization } = useContext(LocalizationContext);

    const [sortType, setSortType] = useState(SortType.name);

    const dateSorter = (a: GroupUser, b: GroupUser): number => {
        return moment(b.dateJoined).valueOf() - moment(a.dateJoined).valueOf();
    };

    const exchangeCountSorter = (a: GroupUser, b: GroupUser): number => {
        return b.publishedExchanges - a.publishedExchanges;
    };

    const nameSorter = (a: GroupUser, b: GroupUser): number => {
        return a.firstName.localeCompare(b.firstName);
    };

    let sorter = nameSorter;

    if(sortType === SortType.date){
        sorter = dateSorter;
    }
    else if(sortType === SortType.count){
        sorter = exchangeCountSorter;
    }


    return (
        <div>
            <div className={style.groupUsers}>
                <div className={style.heading}>
                    <h3>JEUeurs <span>({users.length})</span> <FontAwesomeIcon icon="refresh" onClick={refresh} /></h3>
                    <div className={style.sorter}>
                        <div>
                            <button onClick={() => setSortType(SortType.name)} className={sortType === SortType.name ? style.active : null}>prénom</button>
                            <button onClick={() => setSortType(SortType.date)} className={sortType === SortType.date ? style.active : null}>date</button>
                            <button onClick={() => setSortType(SortType.count)} className={sortType === SortType.count ? style.active : null}>{localization["countSort"]}</button>
                        </div>
                    </div>
                </div>
                {
                    users.length > 0 &&
                    users.sort(sorter).map(user => {
                        return (
                            <div className={style.u} key={user.personId}>
                                <div>
                                    <Link to={`../user/${user.personId}`} className={style.n}>
                                        <p>{user.firstName} {user.lastName} ({user.displayName})</p>
                                        <span>{user.emailAddress}</span>
                                    </Link>
                                </div>

                                <div className={style.join}>
                                    <span className={style.d}>{localization["joinDate"]}</span>
                                    <p>{moment(user.dateJoined).format("MMM DD, YYYY")}</p>
                                </div>
                                <div className={style.exchanges}>
                                    <p>{localization["exchangeCount"]}: {user.publishedExchanges}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div >
    );
};

export const GroupUsersManage = (props: GroupUsersManageProps): ReactElement => {

    const { groupId, groupName, refresh, users } = props;

    return (

        <div className={style.innerContainer}>
            {
                IsUndefinedOrNull(props.subOption) &&
                <DefaultView users={users} refresh={refresh} />
            }
            {
                props.subOption === "invite" &&
                <InvitationForm groupId={groupId} groupName={groupName} />
            }
        </div>

    );
}