import { createCustomEqual, deepEqual } from "fast-equals";
import { useEffect, useRef } from "react";

function isLatLngLiteral(obj: any): obj is google.maps.LatLngLiteral {
    return (typeof obj === "object" && Number.isFinite(obj.lat) && Number.isFinite(obj.lng));
}


const deepCompareEqualsForMaps = createCustomEqual(() => ({
    areObjectsEqual: (a, b) => {
        if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        return deepEqual(a, b);
    },
}));

function useDeepCompareMemoize(value: any) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}