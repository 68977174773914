import { StringIsNullOrEmpty } from "../misc/Utilities";
import style from "../styles/radio.module.scss";

export interface IRadio {
    label?: string;
    value: string | number;
    group: string;
    onChange: (event: any) => void;
    disabled?: boolean;
    currentValue: string | number;
}

const Radio = (props: IRadio) => {

    const { label, value, currentValue, group, onChange, disabled } = props;
 
    return (

        <div className={style.container}>
            <label>
                <input onClick={onChange}
                    disabled={disabled}
                    type="radio"

                    checked={currentValue === value}
                    name={group}
                    value={value} />
                {

                }
                {
                    !StringIsNullOrEmpty(label) &&
                    <span>{label}</span>
                }

            </label>
        </div>
    );
}

export { Radio }